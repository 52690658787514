.faq {
    padding: 60px 0;
    position: relative;
}
.faq_block {
    display: flex;
}
.faq_left {
    flex: 1;
}
.faq_right {
    flex: 0 1 660px;
    max-width: 660px;
}
.faq_titles {
    position: sticky;
    top: 80px;
}
.faq_titles img {
    margin-bottom: 20px;
    mix-blend-mode: multiply;
}
.faq_title {
    font-family: InterT;
    font-size: 50px;
    line-height: 1.1;
    /* text-transform: uppercase; */
}
.accordion {
    max-width: 685px;
    margin: 0 auto;
}
.question {
    padding: 17px 50px 20px 0;
    font-size: 18px;
    line-height: 1.55;
    font-weight: 400;
    position: relative;
    font-family: 'InterT';
    cursor: pointer;
    border-bottom: 1px solid #CFD6DC;
    transition: .2sease-in-out;
}
.question::before {
    content: '+';
    width: 24px;
    height: 24px;
    border: 2px solid #2B3137;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 0;
    font-size: 24px;
    font-weight: 300;
    font-family: Qanelas;
    transition: .2s ease-in-out;
}
.question:hover {
    border-color: #5AA675;
}
.active .question {
    color: #5AA675;
    border-color: #5AA675;
}
.active .question::before {
    transform: rotate(45deg);
    color: #5AA675;
    border-color: #5AA675;
}
.question:hover::before {
    color: #5AA675;
    border-color: #5AA675;
}
.oferta {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    color: gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.answer {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 0;
    transition: .2s ease-in-out;
    color: #535F59;
}
.active .answer {
    padding-top: 14px;
    max-height: 550px;
    padding-bottom: 25px;
}
.answer p {
    margin-bottom: 15px;
}
.answer p span {
    display: inline-block;
}
.faq ul li {
    list-style-type: disc;
    margin-bottom: 10px;
    margin-left: 30px;
}
.answer a {
    color: #5AA675;
    font-weight: 500;
}
.btn {
    position: relative;
    transition: .2s ease-in-out;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    text-align: center;
    width: max-content;
    padding: 25px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.36deg, #54AC3B -50.46%, #23845D 99.6%), #FFFFFF33;
    border-radius: 60px;
    cursor: pointer;
    margin: 30px 0px;
}
.btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #FFFFFF33;
}

.btn:hover {
    transform: translateY(-6px);
}

.btn p {
    position: relative;
}
.countdown {
    display: none;
    border-radius: 20px;
    padding: 23px 32px;
    align-items: center;
    /* justify-content: center; */
    max-width: max-content;
    gap: 20px;
    max-width: 420px;
    width: 100%;
}
.countdown > p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
}
.countdown_timer {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.countdown_timer > div {
    display: flex;
    justify-content: flex-start;
    color: #5AA675;
    font-weight: 600;
    font-size: 56px;
}
.countdown_timer span {
    margin-left: -9px;
    margin-right: 2px;
    position: relative;
    top: -3px;
}
.countdown_text {
    display: none;
    max-width: 340px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: -10px;
    font-size: 20px;
    justify-content: center;
}

.countdown_timer_text p{
    color: #000;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.btn {
    display: none;
}
.hide_btn {
    display: flex;
}

@media(max-width: 767px) {
    .oferta {
        margin-top: 50px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        color: gray;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: -85px;
    }
    .faq {
        padding: 40px 0;
    }
    .faq_title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
        padding-left: 0;
    }
    .faq_title img {
        display: none!important;
    }
    .question {
        font-size: 16px;
        line-height: 22px;
    }
    .answer {
        font-size: 14px;
        line-height: 21px;
    }
    .btn {
        display: flex;
        width: 100%;
        font-size: 18px;
    }
    .countdown {
        display: flex;
        margin: 0px auto;
        margin-left: 10px;
        justify-content: center;

    }
    .countdown_timer {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .countdown_timer > div {
        font-size: 40px;
        min-width: 140px;
    }
    .countdown_text {
        display: flex;
    }
    .hide_btn {
        display: none;
    }

}
@media(max-width: 575px) {
    .faq {
        background-position: 50% 50%;
    }
    .question {
        padding: 10px 45px 10px 0;
    }
    .question::before {
        right: 0;
    }
    .answer {
        padding-left: 0;
    }
    .faq_block {
        flex-wrap: wrap;
    }
    .faq_left, .faq_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .faq_title {
        position: relative;
        top: 0;
    }
    .faq_titles img {
        display: none!important;
    }
    .faq_title br {
        display: none;
    }
    .faq ul li {
        margin-left: 17px;
    }
}