.author {
    padding-bottom: 70px;
    /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/springawake/2/author_bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat; */
    background-color: #FFF9F5;
    position: relative;
}
.author_block {
    display: flex;
    gap: 40px;
    margin-bottom: 72px;
}
.author_img {
    flex: 0 1 510px;
    max-width: 510px;
    text-align: center;
    position: relative;
}
.author_img img {
    position: absolute;
    width: 690px;
    max-width: max-content;
    left: -200px;
    top: 45px;
}
.author_info {
    flex: 1;
    padding-top: 80px;
}
.author_pretitle {
    /* border: 1.25px solid #E8507B; */
    border-radius: 8px;
    padding: 10px 16px;
    color: #473622;
    font-size: 20px;
    font-weight: 400;
    line-height: 0.8;
    background-color: #F5ECE1;
    margin-bottom: 24px;
    max-width: max-content;
}
.author_title {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 44px;
    line-height: 1.15;
    color: #111010;
    text-transform: uppercase;
}
.author_title span {
    color: #E8507B;
}
.author_subtitle {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
}
.author_subtitle span {
    color: #E8507B;
}
.author_preims {
    display: flex;
    gap: 30px;
    max-width: 1180px;
    /* margin: -80px auto 0; */
    /* padding: 36px 0; */
    /* border-top: 1px solid #FED7E0; */
    /* border-bottom: 1px solid #FED7E0; */
    /* background: #fff4f3; */
    z-index: 1;
    position: relative;
}
.author_item {
    flex: 0 1 20%;
    max-width: 20%;
}
.author_item_name {
    margin-bottom: 4px;
    color: #E8507B;
    font-weight: 800;
    font-size: 44px;
    line-height: 1.15;
}
.author_item_text {
    line-height: 1.3;
}
.author_list li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.35;
    transition: .3s ease-in-out;
}
.author_list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 8px;
    height: 8px;
    background-color: #E8507B;
    border-radius: 100%;
}
.author_list li:last-of-type {
    margin-bottom: 0;
}
.author_list li b {
    font-weight: 600;
}
.author_list li:hover {
    transform: translateX(3px);
}

.author_imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    /* margin-bottom: 60px;     */
}
.author_imgs_item:nth-child(odd) {
    transform: rotate(-2deg);
}
.author_imgs_item:nth-child(even) {
    transform: rotate(2deg);
}
.author_imgs_item {
    background-color: #fff;
    /* border: 1px solid #2B2B2B; */
    border-radius: 8px;
    padding: 15px;
    transition: .3s ease-in-out;
}
.author_imgs_item p {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
}
.author_imgs_item:hover {
    z-index: 2;
    transform: scale(1.1);
    box-shadow: 0px 8px 64px 0px #DE64F566;
}
.author_imgs:has(.author_imgs_item:hover) .author_imgs_item {
    filter: grayscale(1)
}
.author_imgs_item:hover {
    filter: grayscale(0)!important;
}
.mob {
    display: none;
}
@media(max-width: 575px) {
    .author {
        padding: 0 0 50px;
        background-image: none;
    }
    .author_title {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: left;
    }
    .author_subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .author_block {
        margin-bottom: 0;
        padding-right: 0;
    }
    .author_preims {
        flex-direction: column;
        padding: 12px 0;
        gap: 10px;
        margin-top: 0;
    }
    .author_img {
        display: none;
    }
    .mob {
        display: block;
    }
    .author_list {
        display: none;
    }
    .mob .author_list {
        display: block!important;
        margin-bottom: 30px;
    }
    .author_list li {
        font-size: 16px;
    }
    .author_info {
        padding-left: 0;
        padding-top: 40px;
    }
    .author_pretitle {
        font-size: 18px;
        text-align: center;
        margin: 0px 0px 20px;
        
    }
    .author_item {
        flex: 0 1 100%!important;
        max-width: 100%!important;
        display: flex;
        margin-bottom: 12px;
        border-bottom: 1px solid #FFE1EB;
        padding: 0 0 12px;
    }
    .author_item:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .author_imgmob {
        margin-bottom: -40px;
        margin-left: -40px;
        width: 300px;
    }
    .author_item_name {
        flex: 0 1 109px;
        max-width: 109px;
        font-size: 20px;
    }
    .author_item_text {
        flex: 1;
        font-size: 14px;
    }
    .author_imgs {
        gap: 10px;
    }
    .author_imgs_item {
        width: 162px;
    }
}
@media(max-width: 363px) {
    .author_imgs_item {
        width: 150px;
    }
}
@media(max-width: 340px) {
    .author_imgs_item {
        width: 140px;
    }
}