.block1 {
    padding: 0 0 100px;
    background-color: #FEF0F0;
}
.top {
    display: flex;
    gap: 0px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 45px;
}
.top_left {
    flex: 1;
}
.top_right {
}
.t1 {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 50px;
    line-height: 1.1;
    text-align: center;
    color: #14040A;
    letter-spacing: -0.28px;
}
.t1 span {
    color: #E8507B;
}
.t1 .line {
    color: #DCA7A7;
    text-decoration: line-through;
}
.st1 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
}
.timer1 > div { 
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 50px;
    line-height: 1.1;
    color: #44A56F;
}
.timer1 > div span {
        margin-right: 0px;
        margin-left: -5px;
}
.timer1 > div div {
}
.block {
    display: flex;
    align-items: flex-start;
    border-radius: 16px;
    box-shadow: 0px 21px 21px 0px #F4F4FF17;
    background-color: #FFF;
}
.block2 {
    background-color: #F7F7F7;
}
.block_01 {
    margin-top: 18px;
}
.block_left {
    flex: 0 1 712px;
    max-width: 712px;
    padding: 60px 0px 60px 60px;
}
.old_prise {
    display: inline-block;
    color: #BEBEBE;
    text-decoration: line-through;
}
.block_right {  
    flex: 2 1 488px;
    text-align: right;
    max-width: 488px;
}
.block_img {
    margin-bottom: 40px;
}
.block_img img {
    border-radius: 28px;
    background-color: rgba(255, 255, 255, 0.16);
}
.block_pretitle {
    margin-bottom: 25px;
    /* text-transform: uppercase; */
    color: #E8507B;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 1.18px;
    max-width: max-content;
}
.block_pretitle p {
    padding: 4px 19px;
    display: inline-block;
    border-radius: 10px;
    background: #F7E6E6;
    margin-right: 20px;
}
.prize {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    display: flex;
    gap: 19px;
    margin-top: 69px;
}
.prize span {
    color: #E8507B;
}
.block_aim {
    position: relative;
}
.aim {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    display: flex;
    padding: 16px 17px 16px 17px;
    border-radius: 16px;
    background-color: #FFFFFF;
    flex-direction: column;
    position: absolute;
    text-align: left;
    left: 268px;
    top: 252px; 
    min-width: 209px;
}
.aim p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}
.aim span {
    color: #E8507B;
    font-weight: 600;
}
.block_title {
    font-size: 44px;
    font-weight: 900;
    line-height: 1.15;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #111010;
}
.block_title span {
    color: #E8507B;
}
.block_text {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #282828;
    margin-bottom: 20px;
}
.block_text ul li{
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
}
.block_text ul li::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #E8507B;
}
.block_text span{
    font-family: Manrope;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
}
.block_phone {
    position: relative;
    text-align: left;
    padding: 60px 60px 50px 71px;
}
.block_phone_2 {
    padding: 90px 60px 0px 71px;
}
.block_phone > div {
    position: absolute;
    border-radius: 15px;
    background: #FEF0F0;
    padding: 15px;
    display: flex;
    gap: 10px;
    text-align: left;
    align-items: center;
    filter: drop-shadow(0px 4px 16.3px rgba(131, 87, 95, 0.16));
}
/* .block_phone > div::before {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    margin-top: -14px;
    width: 13px;
    height: 27px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="27" viewBox="0 0 13 27" fill="none"><path d="M0 13.5L13 0V27L0 13.5Z" fill="white"/></svg>');
} */
.block_phone > div > div:nth-of-type(1) {
    color: #E8507B;
    font-family: Stolzl;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
}
.block_phone > div > div:nth-of-type(2) {
    font-family: Manrope;
    font-size: 14px;
    line-height: 1.1;
}
.block_phone_1 {
    top: 15%;
    right: 40px;
}
.block_phone_2 {
    top: 45%;
    left: 35px;
}
.block_phone_2::before {
    transform: rotate(180deg);
    left: auto!important;
    right: -13px;
}
.block_phone_3 {
    top: 72%;
    right: 35px;
}
@media(max-width: 575px) {
    .timer1 > div div {
        min-width: 20px;
    }
    .timer1 > div span {
        margin-left: -5px;
    }
    .block {
        padding: 0;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
    }
    .top {
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;
        gap: 0px;
    }
    .top_right {
        flex: 0 1 100%;
        display: flex;
        width: 100%;
        justify-content: center;
        max-width: 100%;
    }
    .block1 {
        padding: 40px 0;
    }
    .block_text span{
        font-size: 18px;
    }
    .block_phone {
        padding: 0px 20px 20px;
    }
    .block_phone_2 {
        padding: 0px 20px 0px;
    }
    .prize {
        font-size: 14px;
        margin-top: 20px;
    }
    .block_right {  
        flex: auto;
        max-width: max-content;
    }
    .aim {
        top: 350px;
        left: 50px;
    }
    .t1 {
        font-size: 28px;
    }
    .st1 {
        font-size: 18px;
        margin-bottom: 0;
    }
    .timer1 > div {
        font-size: 36px;
        justify-content: flex-start;
    }
    .block_left {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0;
        padding: 35px 20px 0;
    }
    .block_img {
        display: none;
    }
    .block_pretitle {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .block_title {
        margin-bottom: 12px;
        font-size: 24px;
    }
    .block_text {
        font-size: 18px;
    }
    .block_phone > div {
        padding: 10px;
    }
    .block_phone > div div:nth-of-type(1) {
        font-size: 20px;
    }
    .block_phone > div div:nth-of-type(2) {
        font-size: 14px;
    }
    .block_phone br {
        display: block!important;
    }
    .block_phone_1 {
        right: 10px;
        top: 10%;
    }
    .block_phone_2 {
        left: 0px;
    }
    .block_phone_3 {
        right: 10px;
    }
    .block_pretitle p {
        margin-bottom: 10px;
    }
}