.reviews {
    /* background-color: #0b0b0b; */
    overflow: hidden;
    padding: 60px 0;
}
.reviews_title {
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.3;
}
.reviews_title span {
    color: #8FA8E8;
}

.block {
    display: flex;
    align-items: center;
    gap: 40px;
}
.swiper {
    flex: 1;
}
.slide {
    text-align: center;
}

.slider_btn_prev, .slider_btn_next {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #FF84A9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.slider_btn_prev:hover, .slider_btn_next:hover {
    opacity: .8;
}
.slider_btn_prev img, .slider_btn_next img {
    transition: .3s ease-in-out;
}
.slider_btn_next {
    transform: rotate(180deg);
}
.promo_btn {
    margin: 26px auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 405px;
    height: 100px;
    padding: 10px;
    border-radius: 60px;
    background: #FF84A9;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    font-family: Manrope;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.remains_n_place {
    font-family: Inter;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    gap: 10px;
}
.remains_n_place div {
    display: inline-block;
}
.remains_n_place span {
    margin-bottom: 0px;
    color: #FF84A9;
    font-family: Nface;
    line-height: 1.1;
    letter-spacing: -0.478px;
    font-size: 40px;
}
.remains_n_text {
    color: #FF724D;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
} 
.promo_countdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.promo_countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
}
.promo_countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FF84A9;
    font-size: 60px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
}
.promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
}

@media(max-width: 575px) {
    .promo_countdown {
        order: 3;
        text-align: center;
        margin-bottom: 10px;
    }
    .promo_countdown_timer > div {
        justify-content: center;
        font-size: 48px;
    }
    .remains_n_place br {
        display: block !important;
    }
    .reviews {
        padding: 10px 0 50px;
    }
    .reviews_title {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .block {
        gap: 10px;
    }
    .slider_btn_next, .slider_btn_prev {
        width: 30px;
        height: 30px;
        padding: 8px;
    }
    .promo_btn {
        width: 100%;
        font-size: 18px;
        height: 80px;
        padding: 10px;
    }
}