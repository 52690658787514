.promo {
    padding-top: 85px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/promo.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 760px;
}
.promo_pretitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 35px;
}
.promo_pretitle p {
    color: #B67B8C;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: rgba(240, 235, 239, 0.72);
}
.promo_pretitle br {
    display: none!important;
}
.promo_title {
    font-family: InterT;
    font-size: 60px;
    font-weight: 500;
    line-height: 0.95;
    letter-spacing: -0.6px;
    margin-bottom: 20px;
}
.promo_title span {
    color: #EE2C60;
    padding-left: 15px;
}
.promo_subtitle {
    margin-bottom: 50px;
    color: rgba(40, 40, 40, 0.8);
    font-size: 20px;
    line-height: 1.4;
}
.promo_bottom_block {
    display: flex;
    gap: 25px;
}
.prize {
    display: flex;
    padding: 16px 30px;
    gap: 22px;
    background-color: #ECECEC;
    border-radius: 30px;
    align-items: center;
    justify-content: flex-start;
}
.prize_text {
    font-family: 'Inter';
    font-size: 32px;
    font-weight: 400;
    line-height: 1.25;
    color: #707B96;
}
.prize_text span {
    color: #FE5858;
}
.prize_text b {
    font-weight: 600;
}
.buttons {
    display: flex;
    gap: 14px;
    margin-bottom: 35px;
}
.btn {
    transition: .2s ease-in-out;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 1.4;
    text-align: center;
    width: 275px;
    padding: 25px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(91deg, #EE2C60 -28.4%, #CE0037 139.07%);
    cursor: pointer;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn2 {
    background: #fff;
    color: #EE2C60;
    border-radius: 60px;
    border: 1px solid #EE2C60;
}
.promo_discount {
    font-size: 27px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.336px;
}
.promo_discount span {
    color: #EE2C60;
}
.promo_discount_mob {
    display: none;
    font-size: 27px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.336px;
}
.promo_discount_mob span{
    color: #EE2C60;
    font-size: 34px;
}
.countdown {
    background-color: #2F8FD5;
    border-radius: 20px;
    padding: 23px 32px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    max-width: max-content;
    gap: 20px;
    max-width: 420px;
    width: 100%;
    margin: 0 auto 40px; 
}
.countdown > p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
}
.countdown_timer {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.countdown_timer > div {
    display: flex;
    justify-content: flex-start;
    color: #EE2C60;
    font-weight: 500;
    font-size: 56px;
}
.countdown_timer span {
    margin-left: -9px;
    margin-right: 2px;
    position: relative;
    top: -3px;
}
.countdown_timer_text p{
    color: #000;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.promo_mob_img {
    display: none !important;
    height: auto;
}
.promo_subtitle b {
    font-weight: 600;
}
@media(max-width:575px) {
    .promo {
        background-image: none;
        height: auto;
        padding-top: 30px;
        padding-bottom: 130px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #F9FEFF;
    }
    .img_shadow {
        position: relative;
    }
    .promo_mob_img {
        position: relative;
        display: block !important;
        height: auto;
        margin-left: -20px;
        width: calc(100% + 40px);
    }
    .promo_pretitle {
        margin-bottom: 16px;
        width: 100%;
    }
    .promo_pretitle br {
        display: block!important;
    }
    .promo_pretitle p {
        color: #797F84;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 2px;
        background-color: transparent;
    }
    .promo_pretitle img {
        display: none!important;
    }
    .promo_title {
        margin-bottom: 15px;
        font-size: 45px;    
        line-height: .95;
        letter-spacing: -0.45px;
        position: relative;
    }
    .promo_title span {
        position: absolute;
        left: 200px;
        top: 0;
    }
    .promo_title br {
        display: block!important;
    }
    .countdown_timer > div {
        font-size: 38px;
    }
    .countdown_timer {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .promo_subtitle {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .prize {
        align-items: flex-start;
        padding: 14px;
        gap: 35px;
        margin-bottom: 15px;
    }
    .prize img {
        max-width: 79px;
    }
    .prize_text {
        font-size: 18px;
    }
    .buttons {
        flex-direction: column;
        margin-bottom: 21px;
        margin-top: -15px;
        position: relative;
    }
    .btn {
        font-size: 18px;
        width: 100%;
        height: 60px;
        margin-bottom: 0px;
    }
    .promo_discount {
        display: none;
        font-size: 20px;
        width: max-content;
        margin: 0 auto;
        text-align: center;
    }
    .promo_discount_mob {
        font-size: 20px;
        width: max-content;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
    .promo_discount_mob span{
        color: #EE2C60;
        font-size: 34px;
    }
}
@media(max-width: 359px) {
    .promo_title span {
        left: 190px;
    }
}