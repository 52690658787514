
.programs {
    padding: 90px 0;
    position: relative;
    background-color: #FEF0F0;
}
.programs_titles {
    display: flex;
    margin-bottom: 45px;
    gap: 20px;
    align-items: center;
}
.programs_titles span {
    color: #E8507B;
}
.programs_titles_left {
    flex: 1;
}
.programs_titles_right {
    /* flex: 0 1 287px; */
    /* max-width: 287px; */
    display: flex;
    /* flex-direction: column; */
    gap: 16px;
}
.programs_titles_title {
    font-weight: 800;
    font-size: 44px;
    color: #111010;
    line-height: 1.15;
    text-transform: uppercase;
    margin-bottom: 12px;
}
.programs_titles_prices {
    font-size: 20px;
    line-height: 1.35;
}
.programs_titles_prices span {
    color: #F9658B;
    font-weight: 600;
}
.programs_titles_item {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    gap: 16px;
    flex-direction: column;
    flex: 1 1 180px;
}
.programs_titles_item_img {
    flex: 0 1 48px;
    max-width: 48px;
}
.programs_titles_item_info {
    flex: 1;
}
.programs_titles_item_info p {
    line-height: 1.25;
}
.programs_titles_item_info p:first-of-type {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 4px;
    color: #312F2F;
}
.programs_block {
    max-width: 1300px;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0px auto 40px;
}
.programs_item {
    max-width: 360px;
}
.programs_info {
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 4px 12px -2px rgba(16, 24, 40, 0.02), 0px 12px 24px -4px rgba(16, 24, 40, 0.04);
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .3s ease-in-out;
}
.programs_info:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.programs_img {
    position: relative;
    text-align: right;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img img {
    pointer-events: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.programs_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: auto;
}
.programs_tag {
    padding: 0 8px;
    background-color: #E8507B;
    border-radius: 19.333px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    line-height: 2;
    color: #fff;
}
.programs_tag_new {
    color: #fff;
    background-color: #E8507B;
}
.programs_img__info_title {
    letter-spacing: 0.03rem;
    color: #14040A;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}
.programs_img__info_title span {
    display: block;
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: bold;
}
.programs_img__info_price {
    position: relative;
    max-width: max-content;
    color: #14040A;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
}
.programs_img__info_price span {
    font-family: Manrope;
}
.programs_img__info_price::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    width: 100%;
    background-color: #E8507B;
}
.programs_text {
    padding: 16px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.programs_text p{
    margin-bottom: auto;
    padding-bottom: 8px;
}
.programs_text ul {
    padding-bottom: 20px;
    margin-bottom: auto;
}
.programs_text ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.54;
}
.programs_text ul li b {
    font-weight: 600;
}
.programs_more {
    border-top: 1px solid #eaeaea;
    padding: 20px 0 2px;
}
.programs_more input {
    display: none;
}
.programs_more label {
    position: relative;
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
}
.programs_more label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 14px;
    height: 2px;
    background-color: #F9658B;
}
.programs_more label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 2px;
    width: 2px;
    height: 14px;
    background-color: #F9658B;
}
.programs_more p, .programs_more label div {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: normal;
    color: #787878;
    font-size: 16px;
    line-height: 1.45;
    padding-top: 12px;
    font-family: Manrope;
    text-transform: initial;
    transition: .3s ease-in-out;
}
.programs_more p b {
    font-weight: 600;
    color: #343231;
}
.programs_more label div ul {
    padding-bottom: 0;
}
.programs_more label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.programs_more label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #E8507B;
    border-radius: 100%;
}
.programs_more input[type=checkbox]:checked + label p, 
.programs_more input[type=checkbox]:checked + label div {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
}
.programs_more input[type=checkbox]:checked + label::after {
    opacity: 0;
}
.btns {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 0px auto 0px;

}
.btns p {
    display: flex;
    width: 100px;
    height: 100px;
    align-items: center;
    position: relative;
}
.btns p span {
    position: absolute;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 28px;
    align-items: center;
    transform: rotate(340deg);
}
.btns p::before {
    content: '';
    position: absolute;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/womens_day/tiket.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.programs_btn {
    width: 394px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-radius: 20px;
    background: linear-gradient(0deg, var(--green, #44A56F), var(--green, #44A56F)),
linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px 7.134683609008789px 39.24075698852539px 0px #39A5AE26;
    cursor: pointer;
    position: relative;
    padding: 0 25px;
    text-align: center;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    margin-left: 100px;
}
.programs_btn:hover {
    transform: translateY(-3px);
}
.programs_btn:active {
    background: linear-gradient(0deg, #44A56F, #44A56F), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
}
.promo_discount {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin: 20px auto 0px;
    width: max-content;
    text-align: center;
}
.count span{
    color: #E8507B;
    font-weight: 600;
    font-size: 36px;
}
.left_1 {
    margin-top: 7px;
    font-weight: 500;
    text-align: center;
    color: #E8507B;
}
@media(max-width: 575px) {
    .programs {
        padding: 40px 0;
    }
    .programs_block {
        flex-direction: column;
        align-items: center;
        padding: 0px 20px;
    }
    .promo_discount {
        font-size: 18px;
        width: auto;
    }
    .count span {
        display: block;
    }
    .programs_titles {
        flex-direction: column;
        gap: 32px;
    }
    .programs_titles_title {
        text-align: center;
        font-size: 28px;
        margin-bottom: 20px;
    }
    .programs_titles_prices {
        text-align: center;
        font-size: 18px;
    }
    .programs_titles_right {
        flex: 0 1 100%;
        max-width: 100%;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: 8px;
    }
    .programs_titles_item {
        text-align: center;
        padding: 6px;
        gap: 8px;
        align-items: center;
    }
    .programs_titles_item_info p:first-of-type {
        font-size: 14px;
    }
    .programs_titles_item_info p:last-of-type {
        font-size: 12px;
    }
    .programs_titles_item_img {
        flex: 0 1 32px;
        max-width: 32px;
    }
    .btns {
        padding: 0px 20px;
    }
    .programs_btn {
        width: 100%;
        height: 70px;
        text-align: center;
        font-size: 18px;
        margin-bottom: 0;
        line-height: 1.3;
    }
    .btns p{
        display: none;
    }
    .programs_btn {
        margin-left: 0px;
    }
 }
@media(max-width: 380px) {
    .programs_img__info_title span {
        margin-bottom: 6px;
        font-size: 20px;
    }
}