.author {
    padding-top: 40px;
    padding-bottom: 130px;
    position: relative;
    overflow: hidden;
}
.author_block {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: -50px;
}
.author_img {
    flex: 0 1 482px;
    max-width: 482px;
    text-align: center;
    position: relative;
}
.author_img::before {
    content: '';
    position: absolute;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="828" height="510" viewBox="0 0 828 510" fill="none"><g style="mix-blend-mode:multiply"><path d="M789.547 444.253C693.473 475.796 471.678 523.786 353.084 463.399C204.841 387.917 663.098 30.232 793.904 55.3062C924.711 80.3805 247.408 407.494 -97.1854 225.194C-441.779 42.8945 121.135 -315.317 165.999 -294.524C210.864 -273.731 -38.0251 -70.0416 -275.51 -32.4112" stroke="%23FFBFD2" stroke-width="34"/></g></svg>');
    width: 828px;
    height: 510px;
    right: 0;
    top: 40px;
}
.author_img img {
    position: relative;
}
.author_info {
    flex: 1;
}
.author_title {
    font-family: InterT;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.4px;
}
.author_title span {
    color: #EE2C60;
}
.author_subtitle {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
}
.author_subtitle span {
    color: #B67B8C;
}
.author_preims {
    display: flex;
    gap: 30px;
    max-width: 1180px;
    margin: 0 auto;
}
.author_item {
    flex: 0 1 25%;
    max-width: 25%;
}
.author_item_name {
    margin-bottom: 10px;
    color: #EE2C60;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.4px;
    font-family: InterT;
}
.author_item_text {
    line-height: 1.3;
}
.author_list li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    font-size: 20px;
    line-height: 1.4;
    transition: .3s ease-in-out;
}
.author_list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 8px;
    height: 8px;
    background-color: #EE2C60;
    border-radius: 100%;
}
.author_list li:last-of-type {
    margin-bottom: 0;
}
.author_list li b {
    font-weight: normal;
    /* font-weight: 600; */
}
.author_list li:hover {
    transform: translateX(3px);
}

.author_imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 90px;
    position: relative;
}
.author_imgs_item {
    background-color: #282828;
    border: 1px solid #282828;
    border-radius: 10px;
    padding: 15px;
    transition: .3s ease-in-out;
}
.author_imgs_item p {
    text-align: center;
    color: #fff;
    margin-top: 8px;
    font-size: 14px;
    color: #DCDCDC;
}
.author_imgs_item:hover {
    z-index: 2;
    transform: scale(1.1);
}
.author_imgs:has(.author_imgs_item:hover) .author_imgs_item {
    filter: grayscale(1)
}
.author_imgs_item:hover {
    filter: grayscale(0)!important;
    background-color: #EE2C60;
}
.mob {
    display: none;
}
@media(max-width: 575px) {
    .author {
        padding: 0 0 20px;
        background-image: none;
    }
    .author_pretitle {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .author_title {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: center;
    }
    .author_subtitle {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .author_block {
        margin-bottom: 0;
        padding-right: 0;
    }
    .author_preims {
        flex-direction: column;
        padding: 12px 0;
        gap: 10px;
    }
    .author_img {
        display: none;
    }
    .mob {
        display: block;
    }
    .author_list {
        display: none;
    }
    .mob .author_list {
        display: block!important;
        margin-bottom: 30px;
    }
    .author_list li {
        font-size: 16px;
    }
    .author_info {
        padding-left: 0;
    }
    .author_item {
        flex: 0 1 100%!important;
        max-width: 100%!important;
        display: flex;
        margin-bottom: 12px;
        border-bottom: 1px solid #FFE1EB;
        padding: 0 0 12px;
    }
    .author_item:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .author_imgmob {
        margin-bottom: 25px;
    }
    .author_item_name {
        flex: 0 1 109px;
        max-width: 109px;
        font-size: 20px;
    }
    .author_item_text {
        flex: 1;
        font-size: 14px;
    }
    .author_imgs {
        gap: 10px;
        margin-bottom: 30px;
    }
    .author_imgs_item {
        width: 162px;
    }
}
@media(max-width: 363px) {
    .author_imgs_item {
        width: 150px;
    }
}
@media(max-width: 340px) {
    .author_imgs_item {
        width: 140px;
    }
}