.preims {
    padding-bottom: 80px;
}
.block {
    display: flex;
    align-items: flex-end;
}
.left {
    position: relative;
    margin-right: -124px;
    z-index: 1;
}
.right {
    flex: 0 1 707px;
    max-width: 707px;
    position: relative;
}
.title {
    font-size: 44px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 24px;
}
.title p {
    background-color: #fff;
    padding: 8px 14px;
    color: #333844;
    border-radius: 25px;
    max-width: max-content;
}
.title p span {
    color: #A977FE;
}
.subtitle {
    margin-bottom: 40px;
    color: #717DA1;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.42px;
}
.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 70px;
    background: linear-gradient(276deg, #9D5EFF -51.29%, #C5AAFD 131.26%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 35px;
    cursor: pointer;
    min-width: 315px;
}
.btn:hover {
    transform: translateY(-4px);
}
.right .btn {
    display: none;
}
.countdown {
    position: absolute;
    left: 40px;
    bottom: 40px;
}
.countdown > p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.34px;
}
.countdown_timer div {
    display: flex;
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
}
.right > img:nth-of-type(2) {
    display: none!important;
}

@media(max-width: 575px) {
    .preims {
        padding-bottom: 60px;
    }
    .title p {
        padding: 0;
        font-size: 26px;
    }
    .subtitle {
        font-size: 18px;
    }
    .block {
        flex-wrap: wrap;
    }
    .left {
        margin-left: 0;
        flex: 0 1 100%;
        max-width: 100%;
    }
    .right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .right > img:nth-of-type(1) {
        display: none!important;
    }
    .right > img:nth-of-type(2) {
        display: inline-block!important;
    }
    .countdown {
        left: 25px;
        top: 25px;
        bottom: auto;
    }
    .countdown > p {
        font-size: 15px;
        max-width: calc(100% - 40px);
    }
    .countdown_timer div {
        font-size: 58px;
    }
    .title {
        font-size: 26px;
    }
    .btn {
        font-size: 18px;
        padding: 20px;
        display: none;
        max-width: 100%;
        margin-top: 15px;
        min-width: auto;
    }
    .right .btn {
        display: flex;
    }
}