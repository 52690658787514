.start {
    overflow: hidden;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/womens_day/start_bg.jpg');
    background-size: cover;
    padding: 90px 0;
}
.start_info_title {
    text-align: center;
    margin-bottom: 16px;
    font-family: InterT;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
}
.start_info_subtitle {
    text-align: center;
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 1.3;
    color: #676C72;
}
.start_info_date {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    letter-spacing: 0.02rem;
    line-height: 1;
    color: #676C72;
}
.start_info_date b {
    font-weight: 600;
    color: #2B3137;
}
.start_info_date img {
    position: relative;
    top: -2px;
    margin-right: 10px;
}
.start_info_block {
    display: flex;
    gap: 40px;
    margin-bottom: 80px;
    margin-top: 50px;
}
.start_info_left {
    flex: 0 1 50%;
    max-width: 50%;
    border-radius: 13px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    flex-direction: column;
}
.start_info_right {
    flex: 0 1 50%;
    max-width: 50%;
    border-radius: 13px;
    background: #43546A;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    padding: 24px;
    color: #fff;
    display: flex;
    flex-direction: column;
}
.start_info_title2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
}
.start_info_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.start_info_tag {
    background-color: #328BDE;
    border-radius: 90px;
    padding: 12px;
    font-weight: 600;
    line-height: 0.5;
    color: #fff;
    margin-bottom: 20px;
}
.start_info_right > ul, .start_info_left > ul {
    margin-bottom: 8px;
}
.start_info_right > ul li, .start_info_left > ul li {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(73, 72, 72, .1);
    line-height: 1.3;
}
.start_info_right > ul li {
    border-color: #516073;
}
.start_info_right > ul li b, .start_info_left > ul li b {
    font-weight: 500;
}
.start_info_result {
    margin-bottom: 20px;
}
.start_info_more {
    background-color: #364659;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 9px;
}
.start_info_more p {
    font-size: 18px;
    margin-bottom: 4px;
    line-height: 1.3;
    font-weight: bold;
}
.start_info_end {
    margin-top: auto;
}
.start_info_end p {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
}
.start_info_prices {
    display: flex;
    gap: 20px;
}
.start_info_price {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.3;
    color: #3172D3;
}
.start_info_price2 {
    font-size: 30px;
    line-height: 1.3;
    color: #787878;
    text-decoration: line-through;
}
.start_info_right .start_info_price {
    color: #fff;
}
.start_info_right .start_info_price2 {
    color: #7F98B9;
}
.start_img {
    text-align: center;
    margin-bottom: 12px;
}
.start_block {
    border-radius: 20px;
    background-color: #fff;
    margin: 0 auto;
    max-width: 1180px;
    padding: 80px 100px;
    margin-bottom: 0;
}
.start_block_pretitle {
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    gap: 12px;
    color: #E8507B;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
    letter-spacing: 0.02rem;
}
.start_block_title {
    text-align: center;
    text-transform: uppercase;
    color: #111010;
    font-weight: 800;
    font-size: 44px;
    line-height: 1.15;
    margin-bottom: 20px;
}
.start_block_title span {
    color: #E8507B;
}
.start_block_subtitle {
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 40px;
}
.start_block_price {
    margin-bottom: 26px;
    text-align: center;
}
.start_block_price span {
    color: #111010;
    font-size: 44px;
    font-weight: 800;
}
.start_block_price img {
    margin-top: -4px;
}
.start_block_price p {
    line-height: 1.4;
}
.start_block_price em {
    font-style: normal;
    text-decoration: line-through;
    padding-left: 10px;
    font-size: 30px;
}
.start_block > p {
    margin-top: 20px;
    font-size: 18px;
    color: #787878;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 30px;
}
.start_list {
    border-radius: 20px;
    margin-bottom: 60px;
}
.start_list_head {
    margin-bottom: 24px;
    color: #14040A;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}
.start_list_head div {
    display: flex;
    gap: 16px;
}
.start_list_head div p {
    color: #585656;
    font-weight: normal;
    line-height: 1.4;
    text-decoration: line-through;
}
.start_list_head div span {
    color: #44A56F;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
}
.start_list_item {
    margin-bottom: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid rgb(88, 86, 86, .2);
    display: flex;
    justify-content: space-between;
}
.start_list_item p:first-of-type {
    line-height: 1.3;
    color: #111010;
    flex: 1;
    font-weight: 500;
}
.start_list_item p:last-of-type {
    line-height: 1.3;
    text-decoration: line-through;
    /* width: 75px; */
    text-align: right;
}
.start_list_item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.start_top {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.start_title {
    font-family: InterT;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 1.25;
}
.start_subtitle {
    color: #C1C1C1;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
}
.start_subtitle span {
    color: #F77C42;
}
.start_subtitle em {
    font-style: normal;
    text-decoration: line-through;
}
.start_top_right {
    flex: 0 1 275px;
    max-width: 275px;
    background-color: #2D3030;
    border-radius: 16px;
    padding: 24px;
}
.start_text {
    font-size: 24px;
    line-height: 31.2px;
    color: #4D4D4D;
    margin-bottom: 25px;
}
.start_text span {
    color: #FF3D4F;
    font-weight: 600;
}
.start_text i {
    font-style: normal;
    text-decoration: line-through;
    color: #8C8C8C;
}
.start_dates {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.start_date {
    color: #000019;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
}
.start_date img {
    margin-right: 5px;
}
.start_date:first-of-type {
    margin-right: 22px;
    padding-right: 22px;
    border-right: 1px solid rgba(10, 12, 16, 0.6);
}
.start_start {
    color: #29282B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 19px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.start_start img {
    margin-right: 10px;
}
.programs_counts {
    display: flex;
    justify-content: center;
    color: #5492DA;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.programs_counts > div {
    display: flex;
    margin-left: 10px;
}
.start_credit {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #dedede;
    position: relative;
    padding-right: 100px;
}
.start_credit > img {
    position: absolute;
    right: 20px;
    top: 20px;
    max-width: 48px;
}
.start_credit > p:first-of-type {
    margin-bottom: 40px;
    margin-top: 0;
}
.start_credit__title {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    color: #242928;
}
.start_credit__title span {
    font-family: Inter;
}
.start_credit__text {
    font-size: 18px;
    line-height: 26px;
}
.start_credit__text p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.4;
    /* color: #787878; */
}
.start_credit__text p:last-of-type {
    margin-bottom: 0;
}
.start_prizes {
    margin-bottom: 10px;
}
.start_prizes__item {
    padding: 10px 0;
    border-bottom: 1px solid rgba(74, 72, 79, 0.2);
    font-size: 18px;
    line-height: 24px;
}
.start_parts {
    flex: 0 1 100%;
    max-width: 100%;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #dedede;
    position: relative;
    padding-right: 100px;
}
.start_parts > img {
    position: absolute;
    right: 20px;
    top: 20px;
    max-width: 48px;
}
.start_parts > p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 25, 0.6);
}
.start_parts > p:first-of-type {
    margin-bottom: 40px;
}
.start_parts__title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    color: #060606;
}
.start_parts__text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.4;
    /* color: #787878; */
}
.start_parts__text span {
    font-family: Inter;
}
.start_parts__text strong {
    font-weight: 600;
}
.start_parts_block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    transition: .2s ease-in-out;
    align-items: center;
}
.start_parts:hover .start_parts_block {
    opacity: 1;
    pointer-events: all;
}
.start_parts_number {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: inline-block;
    border-top: 2px solid transparent;
    border-left: 2px solid #383838;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.start_parts_number > * {
    transform: rotate(-45deg);
    color: #1C1C1A;
}
.start_parts_number2 {
    border-top: 2px solid #383838;
}
.start_parts_number3 {
    border-top: 2px solid #383838;
    border-right: 2px solid #383838;
}
.start_parts_number4 {
    border: 2px solid #383838;
}
.start_parts_number::before {
    /* content: ''; */
    position: absolute;
    left: 92px;
    top: 50%;
    margin-top: -82px;
    width: 65px;
    height: 2px;
    background-color: #383838;
    transform: rotate(-45deg);
}
.start_parts_number4::before {
    content: none;
}
.start_parts_item {
    flex: 0 1 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.start_parts_item:nth-of-type(1), .start_parts_item:nth-of-type(2) {
    /* margin-bottom: 12px; */
}
.start_parts_item > p {
    margin-top: 5px;
    font-size: 16px;
    line-height: normal;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #1C1C1A;
}
.start_parts_item > p span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    color: #4D4D4D;
    display: block;
}
.start_parts_item > p b {
    font-family: Inter;
    font-style: normal;
}
.start_parts__btn {
    max-width: 800px;
    height: 90px;
    background: linear-gradient(92.57deg, #474747 -13.7%, #2B2B2C 84.73%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 60px;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}
.start_widget {
    padding: 0 50px;
}
.programs_start {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #3D404F;
    margin-bottom: 50px;
}
.programs_start img {
    margin-right: 10px;
}
.programs_start__item {
    background: #FAF0E8;
    border-radius: 8px;
    margin: 0 7.5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
}
.start_title b {
    display: inline-block;
}
.promo_countdown {
    color: #FFB800;
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.promo_countdown > div {
    display: flex;
    justify-content: center;
}
.promo_countdown span {
    margin-left: -5px;
    margin-right: 5px;
    top: -3px;
    position: relative;
}
.start_countdown {
    display: flex;
    align-items: center;
    margin: 0 auto 40px;
    max-width: 525px;
    gap: 20px;
    padding: 20px 32px;
    border: 1px solid #32B6C1;
    border-radius: 20px;
}
.start_countdown > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    color: #312F2F;
}
.start_countdown_timer {
    font-size: 44px;
    line-height: 1.35;
    color: #32B6C1;
}
.start_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.start_countdown_timer span {
    margin-left: -9px;
    margin-right: 2px;
    position: relative;
    top: -3px;
}
.start_add_title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #1C1C1A;
}
.start_add_subtitle {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #1C1C1A;
}
.start_new {
    display: flex;
    gap: 16px;
    /* flex-direction: column; */
}
.start_parts_mark {
    position: absolute;
    right: 10px;
    top: 22px;
    cursor: pointer;
    transition: .2s ease-in-out;
    display: none!important;
}
.start_parts:hover .start_parts_mark {
    opacity: 0;
    transform: translateX(20px);
}
@media(max-width: 991px) {
    .start_block {
        padding: 40px;
    }
    .start_title {
        font-size: 42px;
        line-height: 46px;
    }
    .start_parts__btn {
        padding: 0 25px;
        max-width: calc(100% - 30px);
    }
}
@media(max-width: 767px) {
    .start {
        padding: 60px 0 60px;
    }
    .frame1 {
        width: 90px;
        height: 50px;
        margin-left: -45px;
        background-size: cover;
        top: 0;
    }
    .frame2 {
        width: 70px;
        height: 35px;
        margin-left: -35px;
        background-size: cover;
    }
    .start_block {
        padding: 20px 15px 40px;
        margin-bottom: 0;
    }
    .start_pretitle {
        display: block;
        background-color: #F450F7;
        font-weight: 500;
        color: #fff;
        letter-spacing: -0.04rem;
        font-size: 18px;
        line-height: 18px;
        width: 275px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        margin: 0 auto 14px;
    }
    .start_title {
        font-size: 24px;
        margin-bottom: 8px;
    }
    .start_title em {
        display: block;
        margin-bottom: 12px;
        font-size: 34px;
        line-height: 34px;
        text-transform: uppercase;
    }
    .start_subtitle {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .start_date {
        flex-direction: column;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        color: rgba(10, 12, 16, 0.6);
    }
    .start_date img {
        margin-bottom: 8px;
    }
    .start_date:first-of-type {
        border-right: none;
        padding-right: 5px;
        margin-right: 5px;
    }
    .start_parts_number::before {
        content: none;
    }
    .start_guarantee__title, .start_parts__title {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 5px;
    }
    .start_guarantee__text, .start_parts__text {
        font-size: 16px;
        line-height: 22px;
    }
    .start_parts_block {
        flex-wrap: wrap;
        padding-bottom: 25px;
        margin-bottom: 0;
    }
    .start_parts_item {
        flex: 0 1 50%;
        max-width: 50%;
        margin-bottom: 12px;
    }
    .start_parts_item:nth-of-type(1), .start_parts_item:nth-of-type(2) {
        margin-bottom: 24px;
    }
    .start_parts_item > p br {
        display: block;
    }
    .start_widget {
        padding: 0;
    }
    .programs_start {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    .programs_start__item {
        margin-bottom: 15px;
        justify-content: center;
        font-size: 16px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding: 10px;
    }
    .programs_start__item span {
        display: contents;
    }
    .programs_counts {
        text-align: center;
        flex-direction: column;
        align-items: center;
    }
    .programs_counts > div {
        margin-left: 0;
    }
}
.dts {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex: 1;
    margin-bottom: 40px;
}
.dts_item {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    border: 1px solid #E0E7DA;
    background: #F4F7F2;
    padding: 20px 30px;
}
.dts_item_text p {
    margin-bottom: 4px;
    font-size: 20px;
    color: #14040A;
    font-weight: 600;
    line-height: 1.4;
}
.dts_item_text span {
    line-height: 1.4;
}
.dts_item img {
    max-width: 48px;
}
.remain {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 40px;
    text-align: center;
}
.remain span {
    color: #44A56F;
    font-weight: bold;
    font-size: 26px;
}
.b800 {
    font-weight: 800;
}
@media(max-width: 575px) {
    .dts {
        gap: 8px;
        padding: 0;
        align-items: normal;
        margin: 0 0 24px;
    }
    .dts_item {
        padding: 4px;
        flex-direction: column;
        gap: 4px;
        text-align: center;
    }
    .dts_item img {
        max-width: 32px;
    }
    .dts_item_text {
        font-size: 14px;
    }
    .dts_item_text p {
        font-size: 14px;
    }
    .dts_item_text span {
        display: block;
        font-size: 12px;
    }
    .start_block_pretitle {
        flex-direction: column;
    }
    .start_block_pretitle img {
        display: none!important;
    }
    .start_list_item {
        gap: 12px;
        font-size: 14px;
        margin-bottom: 8px;
        padding-bottom: 8px;
        /* flex-direction: column; */
    }
    .start_list {
        margin-bottom: 30px;
    }
    .start_list_head {
        font-size: 16px;
    }
    .start_credit > img, .start_parts > img {
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: 12px;
    }
    .start {
        padding: 20px 0;
        background-size: cover;
        background-position: 50% 50%;
    }
    .start_top {
        margin-bottom: 0;
    }
    .start_title {
        font-size: 24px;
        line-height: 1.15;
        margin-top: 24px;
        order: 2;
        margin-bottom: 8px;
    }
    .start_title br {
        display: none!important;
    }
    .start_title p {
        font-size: 16px;
        line-height: 22px;
    }
    .start_text {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 25px;
    }
    .start_parts__title, .start_guarantee__title, .start_credit__title {
        font-size: 18px;
        line-height: 22px;
    }
    .start_credit__title, .start_parts__title {
        margin-bottom: 10px;
    }
    .start_parts__text, .start_guarantee__text, .start_credit__text, .start_credit p {
        font-size: 14px;
        line-height: 20px;
    }
    .start_parts__text br, .start_guarantee__text br, .start_credit__text br {
        display: none;
    }
    .start_parts > p:first-of-type {
        font-size: 14px;
        line-height: 18px;
    }
    .start_countdown > p {
        font-size: 14px;
        flex: 1;
    }
    .start_tog {
        padding: 0;
        border: none;
    }
    .start_countdown_timer {
        font-size: 32px;
    }
    .start_countdown_timer > div {
        justify-content: flex-end;
    }
    .start_countdown_timer span {
        margin-left: -8px;
        margin-right: 0;
        position: relative;
        top: -2px;
    }
    .start_block_title {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .start_block_subtitle {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .start_block_price {
        width: 100%;
        font-size: 22px;
    }
    .start_list_head div {
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
    }
    .start_block_price span {
        font-size: 26px;
    }
    .start_block_price em {
        font-size: 26px;
    }
    .start_block_price p {
        font-size: 14px;
    }
    .start_block_price img {
        margin-left: -5px;
        margin-right: -5px;
    }
    .start_countdown {
        flex-direction: column;
        padding: 12px;
        gap: 0;
    }
    .start_block > p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .start_top {
        flex-wrap: wrap;
    }
    .start_new {
        flex-wrap: wrap;
        gap: 30px;
    }
    .start_credit, .start_parts {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 16px;
    }
    .start_parts_mark {
        display: none;
    }
    .start_parts_block {
        position: relative;
        opacity: 1;
        pointer-events: all;
        padding: 0;
        height: auto;
        margin-top: 25px;
        background-color: transparent;
    }
    .start_info_title {
        font-size: 24px;
    }
    .start_info_subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .start_info_date {
        font-size: 15px;
    }
    .start_info_block {
        margin-top: 20px;
        gap: 12px;
        flex-wrap: wrap;
    }
    .start_info_left, .start_info_right {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 20px;
    }
    .start_info_title2 {
        font-size: 20px;
    }
    .start_info_tags {
        gap: 8px;
        margin-bottom: 20px;
    }
    .start_info_tag {
        margin-bottom: 0;
        font-size: 14px;
    }
    .start_info_end p {
        font-size: 16px;
    }
    .start_info_price, .start_info_price2 {
        font-size: 24px;
    }
    .start_info_more p {
        font-size: 16px;
    }
}

.line {
    position: relative;
    left: 0;
    top: 0;
    width: 5000px;
    z-index: 2;
}
.line_text {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #2C3F3C;
    animation: linemove 20s linear infinite;
    overflow: hidden;
}
.line_text p {
    font-weight: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Stolzl;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
}
.line_text p img {
    margin: 0 20px;
}
@keyframes linemove {
    0% {
        transform: translateX(-564px);
    }
    100% {
        transform: translateX(0);
    }
}
@media(max-width: 575px) {
    .line_text {
        height: 50px;
    }
    .line_text p {
        font-size: 16px;
    }
    @keyframes linemove {
        0% {
            transform: translateX(-482px);
        }
        100% {
            transform: translateX(0);
        }
    }
}