.block2 {
    background-color: #F5ECE1;
    padding: 0px 0 85px;    
    color: #473622;
}
.img {
    padding-top: 40px;
    text-align: center;
    margin-bottom: 2px;
}
.pt {
     /* color: #282828; */
     padding: 4px 19px;
     /* border-radius: 10px; */
     /* background: #F7E6E6; */
     /* text-transform: uppercase; */
     color: #E8507B;
     text-transform: uppercase;
     font-size: 18px;
     font-weight: 400;
     line-height: 1.3;
     letter-spacing: 1.18px;
     max-width: max-content;
     margin: 0 auto 12px;
     text-align: center;
}
.pt b {
    font-weight: 600;
}
.t {
    /* color: #282828; */ 
    font-family: 'Manrope';
    font-size: 45px;
    font-weight: 600;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 45px;
}
.t span {
    color: #F97193;
}
.b {
    display: flex;
    flex-wrap: wrap;
    gap: 57px;
    margin-bottom: 85px;
}
.b_item {
    flex: 0 1 550px;
    max-width: 550px;
    display: flex;
    gap: 28px;
}
.b_item_num {
    font-family: Unisans;
    font-size: 236px;
    color: #E9DED0;
    font-weight: bold;
    line-height: 0.9;
}
.b_item_text div {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
}
.b_item_text p {
    font-size: 18px;
    line-height: 1.4;
}
.btn {
    position: relative;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 448px;
    height: 100px;
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(0deg, #F97193, #F97193), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px -2.62px 2.62px 0px #E6476E inset;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.free {
    display: flex;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}
.free_text {
    text-align: center;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 25px;
}
.free span {
    font-size: 28px;
    color: #ADADAD;
    position: relative;
}
.free span::before {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: solid 2px #ADADAD;
    bottom: 44%;
}
.free div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.free b {
    text-transform: uppercase;
    font-family: Manrope;
    font-size: 40px;
    font-weight: 500;
}
.b2 {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 30px;
    max-width: 1110px;
    margin: 0px auto 128px;
    overflow: hidden;
}
.b2_info {
    flex: 1 1;
    padding: 42px 40px 30px 60px;
}
.b2_info_title {
    font-family: Unisans;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.b2_info_title span {
    color: #0280EF;
}
.b2_info_text {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.4;
}
.b2_info_subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}
.b2_img {
    flex: 0 1 502px;
    max-width: 502px;
}
.br_mob {
    display: none !important;
}
@media(max-width: 575px) {
    .br_mob {
        display: block !important;
    }
    .b2 {
        margin-top: 40px;
        margin-bottom: 80px;
        flex-direction: column;
    }
    .free {
        gap: 5px;
        align-items: center;
        margin-bottom: 15px;
    }
    .free span {
        font-size: 22px;
    }
    .free span::before {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: solid 2px #ADADAD;
        bottom: 44%;
    }
    .free div {
        align-items: center;
    }
    .free b {
        text-align: center;
        font-size: 26px;
        color: #F97193;
    }
    .b2_img {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b2_info {
        padding: 24px;
    }
    .b2_info_title {
        font-size: 20px;
    }
    .b2_info_text {
        font-size: 18px;
    }
    .b2_info_subtitle {
        font-size: 18px;
    }
    .block2 {
        padding: 15px 0;
    }
    .pt {
        font-size: 16px;
        text-align: left;
        padding-left: 0px;
    }
    .t {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: left;
    }
    .btn {
        width: 100%;
        height: 80px;
        font-size: 18px;
    }
    .b {
        gap: 24px;
        margin-bottom: 45px;
    }
    .b_item {
        flex: 0 1 100%;
        max-width: 100%;
        flex-direction: column;
        gap: 12px;
    }
    .b_item_num {
        font-size: 46px;
        line-height: 1;
        color: #b3a28c;
    }
    .b_item_text div {
        font-size: 20px;
    }
    .b_item_text p {
        font-size: 16px;
    }
    .free_text {
        font-size: 20px;
    }
}