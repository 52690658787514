.block1 {
    padding: 50px 0;
}
.top {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}
.top_left {
    flex: 1;
}
.top_right {
    flex: 0 1 450px;
    max-width: 450px;
}
.t1 {
    margin-bottom: 20px;
    font-family: Muller;
    font-weight: bold;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -0.28px;
    color: #441F77;
}
.t1 span {
    color: #EF6EAD;
}
.st1 {
    font-size: 20px;
    line-height: 1.4;
    color: #818496;
    font-weight: 600;
}
.timer1 > div {
    font-family: Muller;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #441F77;
    font-size: 135px;
    line-height: 1.1;
}
.timer1 > div span {
    margin-right: 10px;
    margin-left: -15px;
}
.timer1 > div div {
    min-width: 190px;
}
.block {
    display: flex;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 21px 21px 0px #F4F4FF17;
    background-color: #FFF;
    border: 1px solid #D7D7D7;
}
.block2 {
    background-color: #F7F7F7;
}
.block_left {
    flex: 0 1 640px;
    max-width: 640px;
    padding: 60px 0 60px 64px; 
}
.block_right {
    flex: 1;
    text-align: right;
}
.block_img {
    margin-bottom: 45px;
}
.block_img img {
    border-radius: 28px;
    background-color: rgba(255, 255, 255, 0.16);
}
.block_pretitle {
    margin-bottom: 25px;
    padding: 4px 19px;
    border-radius: 10px;
    background: #F7E6E6;
    text-transform: uppercase;
    color: #FF724D;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 1.18px;
    max-width: max-content;
}
.prize {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    display: flex;
    gap: 19px;
    margin-top: 69px;
}
.prize span {
    color: #FF724D;
}
.block_aim {
    position: relative;
}
.aim {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    display: flex;
    padding: 16px 17px 16px 17px;
    border-radius: 16px;
    background-color: #FFFFFF;
    flex-direction: column;
    position: absolute;
    text-align: left;
    left: 268px;
    top: 252px; 
    min-width: 209px;
}
.aim p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}
.aim span {
    color: #FF724D;
    font-weight: 600;
}
.block_title {
    font-family: Nface;
    font-size: 35px;
    line-height: 1.35;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.block_title span {
    color: #8FA8E8;
}
.block_text {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    color: #6A6A6A;
}
.block_text span{
    font-family: Manrope;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
}
.block_phone {
    position: relative;
    text-align: center;
    padding-top: 40px;
}
.block_phone img {
    max-width: 392px;
}
.block_phone > div {
    position: absolute;
    border-radius: 60px;
    background: #FDD14F;
    padding: 18px;
    display: flex;
    gap: 14px;
    text-align: left;
    align-items: center;
}
.block_phone > div::before {
    content: '';
    position: absolute;
    left: 10px;
    bottom: -8px;
    margin-top: -14px;
    width: 16px;
    height: 32px;
    background-size: cover;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="27" viewBox="0 0 13 27" fill="none"><path d="M0 13.5L13 0V27L0 13.5Z" fill="%23FDD14F"/></svg>');
    transform: rotate(90deg);
}
.block_phone > div > div:nth-of-type(1) {
    color: #6A6A6A;
    font-family: Nface;
    font-size: 27px;
    line-height: 1.3;
    text-transform: uppercase;
}
.block_phone > div > div:nth-of-type(2) {
    line-height: 1.4;
    font-size: 13px;
    color: #6A6A6A;
}
.block_phone_1 {
    top: 15%;
    right: 40px;
}
.block_phone_2 {
    top: 45%;
    left: 35px;
}
.block_phone_2::before {
    transform: rotate(180deg);
    left: auto!important;
    right: 10px;
}
.block_phone_3 {
    top: 72%;
    right: 35px;
}
@media(max-width: 575px) {
    .timer1 > div div {
        min-width: 120px;
    }
    .timer1 > div span {
        margin-left: -5px;
    }
    .block {
        padding: 0;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 0;
    }
    .top {
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;
        gap: 12px;
    }
    .top_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .block1 {
        padding: 40px 0;
    }
    .block_text span{
        font-size: 18px;
    }
    .prize {
        font-size: 14px;
        margin-top: 20px;
    }
    .aim {
        top: 350px;
        left: 50px;
    }
    .t1 {
        font-size: 30px;
    }
    .st1 {
        font-size: 18px;
        margin-bottom: 0;
    }
    .timer1 > div {
        font-size: 70px;
        justify-content: flex-start;
    }
    .block_left {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0;
        padding: 35px 28px 0;
    }
    .block_img {
        display: none;
    }
    .block_pretitle {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .block_title {
        margin-bottom: 12px;
        font-size: 22px;
    }
    .block_text {
        font-size: 18px;
    }
    .block_phone > div {
        padding: 10px;
    }
    .block_phone img {
        max-width: 332px;
    }
    .block_phone > div div:nth-of-type(1) {
        font-size: 20px;
    }
    .block_phone > div div:nth-of-type(2) {
        font-size: 14px;
    }
    .block_phone br {
        display: block!important;
    }
    .block_phone_1 {
        right: 10px;
        top: 10%;
    }
    .block_phone_2 {
        left: 10px;
    }
    .block_phone_3 {
        right: 10px;
    }
}