.programs {
    padding: 60px 0 0;
    position: relative;
    background-color: #fff;
}
.t1 {
    color: #1E1F2E;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.1;
    text-transform: uppercase;
}
.t1 span {
    font-size: 52px;
}
.st1 {
    margin-bottom: 32px;
    text-align: center;
    font-size: 21px;
    line-height: 1.3;   
}
.programs_block {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1500px;
    padding: 0 15px;
    margin-bottom: 30px;
}
.programs_item {
    max-width: 362px;
}
.programs_info {
    border: 1px solid #E1E1FA;
    border-radius: 35px;
    background: linear-gradient(180deg, #171718 0%, #080C1A 100%), #93A1E3;
    box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 4px 12px -2px rgba(16, 24, 40, 0.02);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: .2s ease-in-out;
}
.programs_info:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.programs_img {
    position: relative;
    text-align: right;
    /* height: 224px; */
}
.programs_img img {
    pointer-events: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
}
.programs_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: auto;
}
.programs_tag {
    padding: 0 8px;
    background-color: #fff;
    border-radius: 19.333px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    color: #1E1A1B;
    line-height: 2;
}
.programs_tag_new {
    color: #fff;
    background-color: #FF5C00;
}
.programs_img__info_title {
    letter-spacing: 0.03rem;
    color: #fff;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 16px;
}
.programs_img__info_title span {
    display: block;
    margin-top: 4px;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 600;
}
.programs_img__info_price {
    position: relative;
    max-width: max-content;
    color: #fff;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 600;
}
.programs_img__info_price::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    width: 100%;
    background-color: #FF598B;
}
.programs_text {
    padding: 16px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.programs_text ul {
    padding-bottom: 20px;
    margin-bottom: auto;
}
.programs_text ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.54;
    color: #fff;
}
.programs_more {
    border-top: 1px solid #272733;
    padding: 15px 0 2px;
    overflow: hidden;
}
.programs_more input {
    display: none;
}
.programs_more label {
    position: relative;
    width: 100%;
    display: block;
    color: #fff;
    letter-spacing: 0.015rem;
    font-size: 16px;
    line-height: 1;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}
.programs_more label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 14px;
    height: 2px;
    background-color: #FF598B;
}
.programs_more label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 2px;
    width: 2px;
    height: 14px;
    background-color: #FF598B;
}
.programs_more p, .programs_more label div {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: normal;
    color: #fff;
    font-size: 16px;
    line-height: 1.45;
    padding-top: 12px;
    text-transform: initial;
    transition: .2s ease-in-out;
}
.programs_more p b {
    font-weight: 600;
}
.programs_more label div ul {
    padding-bottom: 0;
}
.programs_more label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.programs_more label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #FF598B;
    border-radius: 100%;
}
.programs_more input[type=checkbox]:checked + label p, 
.programs_more input[type=checkbox]:checked + label div {
    height: max-content;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
}
.programs_more input[type=checkbox]:checked + label::after {
    opacity: 0;
}
.dts {
    display: flex;
    justify-content: center;
    gap: 19px;
    margin-bottom: 30px;
}
.dts_item {
    border-radius: 60px;
    background: rgba(169, 119, 254, 0.09);
    box-shadow: 0px 4px 20px 0px rgba(13, 16, 26, 0.02);
    padding: 8px 20px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    max-width: max-content;
}
.dts_item_text {
    font-size: 19px;
    line-height: 1.1;
    color: #161313;
    font-weight: 600;
}
.btns {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 160px;
}
.promo_btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 54px;
    background: linear-gradient(96deg, #FF77A0 44.96%, #EA6C92 147.95%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 35px;
    cursor: pointer;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.btns_prices_top {
    display: flex;
    align-items: center;
    gap: 14px;
}
.btns_prices_top p {
    color: #FF598B;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.1;
}
.btns_prices_top span {
    color: #A29FA4;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.1;
    text-decoration: line-through;
}
.btns_prices_bottom {
    color: #1E1F2E;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
}
.bg {
    background-size: cover;
    background-position: 50% 50%;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/mu2/programs_bg.jpg');
}

@media(max-width: 575px) {
    .programs {
        padding: 40px 0 0;
    }
    .programs_block {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .t1 {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .t1 span {
        font-size: 26px;
        display: block;
    }
    .st1 {
        font-size: 16px;
    }
    .dts {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
        align-items: center;
    }
    .dts_item {
        align-items: baseline;
    }
    .dts_item_text {
        font-size: 14px;
    }
    .btns {
        flex-direction: column;
        margin-bottom: 60px;
    }
    .promo_btn {
        flex: 0 1 100%;
        max-width: 100%;
        width: 100%;
        padding: 12px 24px;
        font-size: 18px;
    }
    .btns_prices_bottom {
        text-align: center;
        margin-top: 4px;
    }
}
@media(max-width: 380px) {
    .programs_img__info_title span {
        font-size: 20px;
    }
}