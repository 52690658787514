.second {
    position: relative;
    overflow: hidden;
    /* padding-top: 100px; */
    padding-bottom: 100px;
    background-color: #FFF9F5;
    /* border-radius: 100px 100px 0px 0px; */
    /* margin-top: -40px; */
}
@media (min-width:1250px){.container{max-width:1500px}}
.line {
    width: 10000px;
    z-index: 0;
    position: absolute;
    top: 0;
    /* overflow: hidden; */
    transform: rotate(-1.5deg);
    margin-top: -75px;
    margin-left: -30px;
}
.line::before {
    content: '';
    position: absolute;
    left: 0;
    top: 135px;
    width: 100%;
    height: 100%;
    background-color: #BCF6A6;
    transform: rotate(2deg);
    /* z-index: 1; */
}
.line_text {
    background-color: #1E1A1B;
    padding: 21px 0;
    align-items: center;
    display: flex;
    gap: 20px;
    overflow: hidden;
    animation: linemove 30s linear infinite;
}
.line_text p {
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.35;
}
@keyframes linemove {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-609px);
    }
}

.block {
    display: flex;
    gap: 24px;
    margin-bottom: 60px;
}
.item {
    flex: 0 1 calc(33.333333% - 19.2px);
    max-width: calc(33.333333% - 19.2px);
    text-align: center;
    position: relative;
    padding: 0 12px;;
}
.item::before {
    content: '';
    position: absolute;
    right: -13px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #F0E6E8;
}
.item:last-of-type::before {
    content: none;
}
.item_t1 {
    margin-bottom: 6px;
    color: #131313;
    font-family: Bebas;
    text-transform: uppercase;
    line-height: 1.1;
    font-size: 35px;
}
.item_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.item_img img {
    max-width: 64px;
}
.item_t1 {
    flex: 1;
}
.item_t1 span {
    font-family: Inter;
}
.item_t2 {
    line-height: 1.27;
}

.block2 {
    border-radius: 20px;
    /* border: 1px solid #DEDEDE; */
    background-color: #F5ECE1;
    margin: 0px auto;
    position: relative;
    max-width: 100%;
    padding: 58px 40px;
}
.block2_title {
    font-family: 'Manrope';
    color: #473622;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 12px;
    text-align: center;
    
}
.block2_title span {
    color: #F97193;
}
.block2_subtitle {
    text-align: center;
    font-size: 20px;
    line-height: 1.3;
    max-width: 788px;
    margin: 0px auto;
}
.block2::before {
    display: none;
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -34.5px;
    bottom: -33.5px;
    width: 69px;
    height: 67px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/9/arrow_down.svg');
}

.b2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px 30px;
}
.b2_item {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 15px);
    max-width: calc(33% - 18px);
    display: flex;
    gap: 12px;
    align-items: center;
}
.b2_item_img {
    flex: 0 1 130px;
    max-width: 130px;
}
.b2_item_img img {
    max-width: 121px;
}
.b2_item_text {
    flex: 1;
    max-width: 350px;
}
.b2_item_text p:nth-of-type(1) {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 5px;
}
.b2_item_text p:nth-of-type(2) {
    font-size: 18px;
    color: rgba(40, 40, 40, 0.7);
    line-height: 1.3;
}
.b2_item_text p span {
    color: #8256CF;
}
@media(max-width:575px) {
    .b2 {
        gap: 10px;
        /* margin-bottom: 40px; */
    }
    .b2_item {
        flex: 0 1 calc(50% - 5px);
        max-width: calc(50% - 5px);
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
    }
    .b2_item_img {
        flex: 0 1 120px;
        max-width: 120px;
    }
    .b2_item_text p:nth-of-type(1) {
        font-size: 18px;
    }
    .b2_item_text p:nth-of-type(2) {
        font-size: 14px;
    }
    .second {
        padding-bottom: 40px;
        padding-top: 50px;
        /* border-radius: 40px 40px 0px 0px; */
        margin-top: -40px;
    }
    .block {
        flex-wrap: wrap;
        gap: 0;
        margin-bottom: 50px;
    }
    .item {
        flex: 0 1 50%;
        max-width: 50%;
        padding: 0 10px 32px;
        text-align: left;
        margin-bottom: 32px;
        border-bottom: 1px solid #F0E6E8;
    }
    .item::before {
        content: none;
    }
    .item_t1 {
        font-size: 35px;
    }
    .item_t2 {
        font-size: 16px;
    }
    /* .item:nth-of-type(even) {
        padding-right: 0;
    }
    .item:nth-of-type(odd) {
        padding-left: 0;
    } */
    .item {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
    }
    .item:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .block2 {
        padding: 24px 20px 48px;
        border-radius: 24px;
    }
    .block2_title {
        font-size: 22px;
    }
    .block2_subtitle {
        font-size: 16px;
    }
    .block2::before {
        width: 41px;
        height: 40px;
        background-size: cover;
        margin-left: -20px;
        bottom: -20px;
    }
}