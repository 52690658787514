.block1 {
    padding: 100px 0 10px;
}
.top {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}
.top_left {
    flex: 1;
}
.top_right {
    flex: 0 1 450px;
    max-width: 450px;
}
.t1 {
    font-family: Unisans;
    text-align: center;
    margin-bottom: 20px;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
}
.t1 span {
    color: #9069D4;
}
.t1 em {
    font-style: normal;
    font-family: Manrope;
}
.st1 {
    text-align: center;
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 1.4;
}
.timer {
    margin-bottom: 40px;
}
.timer > div {
    font-family: Unisans;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #9069D4;
    font-size: 46px;
    line-height: 1.1;
}
.timer > div span {
    margin-right: 5px;
    margin-left: -5px;
}
.timer1 > div {
    font-family: Muller;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #441F77;
    font-size: 135px;
    line-height: 1.1;
}
.timer1 > div span {
    margin-right: 10px;
    margin-left: -15px;
}
.timer1 > div div {
    min-width: 190px;
}
.block {
    display: flex;
    /* align-items: flex-end; */
    align-items: center;
    border-radius: 50px;
    background: #EBF0EE;
}
.block_left {
    flex: 0 1 590px;
    max-width: 590px;
    padding: 60px 0 60px 60px;
}
.block_right {
    flex: 1;
    text-align: right;
}
.block_img {
    margin-bottom: 40px;
    position: relative;
}
.stiker {
    bottom: 20px;
    right: 60px;
    position: absolute;
    width: max-content;
    height: max-content;
    padding: 5px 15px;
    background: #5AA675;
    color: #fff;
    font-size: 14px;
    border-radius: 20px;
}
.block_img img {
    border-radius: 28px;
    background-color: rgba(255, 255, 255, 0.16);
}
.block_pretitle {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 40px;
}
.block_pretitle p {
    border-radius: 5000px;
    background: #9069D4;
    padding: 4px 20px;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.3;
    letter-spacing: 1px;
    font-weight: 500;
    max-width: max-content;
}
.block_pretitle span {
    font-size: 20px;
    color: #A0A0A0;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.block_pretitle span em {
    font-style: normal;
    text-decoration: line-through;
}
.block_title {
    margin-bottom: 16px;
    font-family: InterT;
    font-size: 40px;
    line-height: 1.1;
}
.block_title span {
    color: #5AA675;
}
.block_text {
    font-size: 22px;
    line-height: 1.4;
    color: rgba(40, 40, 40, 0.8);
}
.block_text b {
    font-weight: 600;
    color: rgba(40, 40, 40, 1);
}
.block_text span {
    font-weight: 600;
    color: #EBFF5E;
}
.block_phone {
    position: relative;
    text-align: left;
}

.block_phone > div {
    position: absolute;
    border-radius: 15px;
    background: #fff;
    padding: 15px;
    display: flex;
    gap: 10px;
    text-align: left;
    align-items: center;
    filter: drop-shadow(0px 4px 16.3px rgba(131, 87, 95, 0.16));
}
.block_phone > div::before {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    margin-top: -14px;
    width: 13px;
    height: 27px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="27" viewBox="0 0 13 27" fill="none"><path d="M0 13.5L13 0V27L0 13.5Z" fill="%23ffffff"/></svg>');
}
.block_phone > div > div:nth-of-type(1) {
    color: #5AA675;
    font-size: 36px;
    font-family: InterT;
    line-height: 1.2;
}
.block_phone > div > div:nth-of-type(2) {
    color: rgba(40, 40, 40, 0.8);
    line-height: 1.3;
}
.block_phone_1 {
    top: 15%;
    right: 40px;
}
.block_phone_2 {
    top: 45%;
    left: 35px;
}
.block_phone_2::before {
    transform: rotate(180deg);
    left: auto!important;
    right: -13px;
}
.block_phone_3 {
    top: 72%;
    right: 35px;
}
.block_phone_4 {
    top: 34%;
    right: 110px;
}
.block_phone_5 {
    top: 53%;
    right: 92px;
}
@media(max-width: 575px) {
    .timer1 > div div {
        min-width: 120px;
    }
    .timer1 > div span {
        margin-left: -5px;
    }
    .block {
        flex-direction: column;
        border-radius: 40px;
    }
    .top {
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;
        gap: 12px;
    }
    .top_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .block1 {
        padding: 40px 0;
    }
    .block_phone img{
        max-width: 280px;
    }
    .t1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .st1 {
        font-size: 18px;
        margin-bottom: 35px;
    }
    .timer1 > div {
        font-size: 70px;
        justify-content: flex-start;
    }
    .block_left {
        flex: 0 1 100%;
        max-width: 100%;
        padding: 0;
        padding: 35px 28px 0;
    }
    .block_img {
        /* display: none; */
    }
    .stiker {
        bottom: -20px;
        right: calc(50% - 85px);

    }
    .block_pretitle {
        margin-bottom: 18px;
    }
    .block_pretitle p {
        font-size: 18px;
    }
    .block_title {
        margin-bottom: 16px;
        font-size: 24px;
    }
    .block_text {
        font-size: 18px;
    }
    .block_phone > div {
        padding: 10px;
    }
    .block_phone > div div:nth-of-type(1) {
        font-size: 20px;
    }
    .block_phone > div div:nth-of-type(2) {
        font-size: 14px;
    }
    .block_phone br {
        display: block!important;
    }
    .block_phone_1 {
        right: 10px;
        top: 10%;
    }
    .block_phone_2 {
        left: 10px;
    }
    .block_phone_3 {
        right: 10px;
    }
    .timer {
        margin-bottom: 10px;
    }
    .timer > div {
        font-size: 26px;
    }
    .timer > div span {
        margin-left: 0;
    }
    .block_pretitle {
        flex-direction: column;
        align-items: flex-start;
    }
}