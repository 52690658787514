.studentres {
    overflow: hidden;
    position: relative;
    padding: 50px 0;
}
.pretitle {
    text-align: center;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 1.4;
}
.title {
    text-align: center;
    font-family: Nface;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 1.3;
}
.title span {
  color: #8FA8E8;
}
.subtitle {
    margin-bottom: 45px;
    text-align: center;
    color: #6A6A6A;
    font-size: 20px;
    line-height: 1.4;
}
.slider_block {
    max-width: 1660px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
}
.slider_nav {
    display: flex;
    gap: 16px;
    position: absolute;
    top: 50%;
    width: calc(100% + 140px);
    justify-content: space-between;
    margin-top: -80px;
    left: -70px;
}
.slider_btn_prev {
    background-color: #C0D5E2;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next {
    background-color: #C0D5E2;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next img,
.slider_btn_prev img {
    /* filter: brightness(0); */
}
.slider {
    margin-bottom: 45px;
}
.slide {
    border: 1px solid #E8E8E8;
    border-radius: 38px;
    background-color: #fff;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 15px;
    color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slide_info div img {
    filter: hue-rotate(345deg) saturate(0.6) contrast(0.6);
    margin-top: -2px;
    margin-right: 2px;
}
.slider .slider_nav {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
}
.slide_img {
    position: relative;
}
.slide_img > p {
    position: absolute;
    left: 18px;
    bottom: 18px;
    border-radius: 55.051px;
    background: #C0D5E2;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    padding: 4px 14px;
}
.slide_img > div {
    position: absolute;
    right: 18px;
    bottom: 18px;
    display: none!important;
}
.btn {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 465px;
    height: 100px;
    padding: 10px;
    border-radius: 60px;
    background: #FF84A9;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    font-family: Manrope;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.promo_remains {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
}
.promo_remains_1 {
  font-size: 18px;
  line-height: 1.25;
}
.promo_remains_1 b {
  display: block;
}
.promo_remains_2 {
  margin-bottom: 3px;
  color: #FF84A9;
  font-family: Nface;
  font-size: 50px;
  line-height: 1.1;
  letter-spacing: -0.478px;
}
.promo_countdown {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promo_countdown p {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: #2B3137;
}
.promo_countdown_timer > div {
  display: flex;
  gap: 3px;
  color: #FF84A9;
  font-size: 60px;
  font-family: Nface;
  line-height: 1.35;
  font-weight: normal;
}
.promo_countdown_timer > div div {
  display: flex;
  gap: 3px;
}
@media (max-width: 575px) {
  .promo_remains_1 {
    text-align: center;
  }
  .promo_remains {
    gap: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .promo_remains br {
      display: block!important;
  }
  .promo_remains_2 {  
      text-align: left;
      font-size: 42px;
      margin-bottom: 0;
      order: 2;
  }
    .studentres {
      padding: 40px 0;
    }
    .pretitle {
      font-size: 18px;
    }
    .title {
      font-size: 22px;
      text-align: center;
    }
    .slider {
      margin: 0 0 30px;
    }
    .btn {
      width: 100%;
      font-size: 18px;
      height: 80px;
      padding: 10px;
    }
    .slide_info div {
      font-size: 13px;
    }
    .slider .slider_nav {
      display: flex;
    }
    .slider_nav {
      position: absolute;
      top: 50%;
      padding: 0 10px;
      left: 0;
      z-index: 2;
      width: 100%;
      justify-content: space-between;
    }
    .slider_btn_next {
      width: 42px;
      height: 42px;
    }
    .slider_btn_prev {
      width: 42px;
      height: 42px;
    }
    .slide_info {
      padding: 17px 8px;
    }
    .title span {
      padding: 0;
      border: none;
    }
    .promo_countdown {
      order: 3;
      text-align: center;
      margin-bottom: 10px;
    }
    .promo_countdown_timer > div {
      justify-content: center;
      font-size: 48px;
    }
  }
  