.line {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
    padding: 14px 0;
    z-index: 99;
}
.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.right {
    display: flex;
    align-items: center;
}
.count {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #161313;
}
.price {
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    color: #F97193;
    margin-right: 25px;
}
.btn, .btn_hidden {
    width: 280px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    background: #F97193;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    transition: .3s ease-in-out;
}
.btn::before, .btn_hidden::before {
    content: '';
    background: #F97193;
    border-radius: 8px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .3s ease-in-out;
}
.btn:active::before, .btn_hidden:active::before {
    background: #F97193;
}
.btn:hover, .btn_hidden:hover {
    transform: translateY(-4px);
}
.btn:hover::before, .btn_hidden:hover::before {
    opacity: 1;
}
.btn p, .btn_hidden p {
    position: relative;
}
.btn_hidden {
    display: none;
}
@media(max-width: 575px) {
    .btn {
        display: none;
    }
    .btn_hidden {
        display: flex;
        width: 100%;
        letter-spacing: 0;
        order: 1;
    }
    .count {
        font-size: 22px;
        line-height: 22px;
    }
    .price {
        font-size: 24px;
        line-height: 32px;
        margin-right: 0;
    }
}