.whom {
    padding: 50px 0;
}
.whom_title {
    font-family: Nface;
    text-align: center;
    margin-bottom: 45px;
    font-size: 35px;
    line-height: 1.35;
    text-transform: uppercase;
}
.whom_title span {
    color: #8FA8E8;
}
.whom_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.whom_item {
    padding: 24px;
    flex: 0 1 380px;
    max-width: 380px;
    background-color: #FAFAFA;
    border: 1px solid #ECECEC;
    box-shadow: 0px 4px 40px 0px #0000000A;
    transition: .3s ease-in-out;
    /* border-radius: 32px; */
    border-radius: 16px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
}
.whom_item:hover {
    background-color: #FDD14F;
}
.whom_item_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
}
.whom_item_number {
    color: #C0D5E2;
    font-size: 29px;
    line-height: 1.1;
    text-transform: uppercase;
    font-family: Nface;
    transition: .3s ease-in-out;
}
.whom_item:hover .whom_item_number {
    color: #8DA50F;
}
.whom_item_img img {
    transition: .3s ease-in-out;
}
.whom_item:hover .whom_item_img img {
    filter: hue-rotate(96deg);
}
.whom_item_title {
    margin-bottom: 10px;
    transition: .3s ease-in-out;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.35;
}
.whom_item_text {
    line-height: 1.35;
    color: #6A6A6A;
    transition: .3s ease-in-out;
}
.whom_text {
    text-align: center;
    padding-bottom: 48px;
    font-size: 24px;
    line-height: 1.3;
}
.whom_img {
    text-align: center;
    position: relative;
    pointer-events: none;
}
.whom_img img:last-of-type {
    position: absolute;
    left: 50%;
    bottom: 10px;
    margin-left: -52px;
    filter: hue-rotate(146deg);
}
.whom_img img:nth-of-type(2) {
    display: none!important;
}
@media(max-width: 575px) {
    .whom {
        padding: 40px 0;
    }
    .whom_title {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .whom_item_title {
        font-size: 18px;
    }
    .whom_item_text {
        font-size: 16px;
    }
    .whom_text {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .whom_item_top {
        margin-bottom: 15px;
    }
    .whom_img img:nth-of-type(1) {
        /* display: none!important; */
    }
    .whom_img img:nth-of-type(2) {
        display: none !important;
    }
    .whom_img img:last-of-type {
        bottom: -5px;
    }
}