.prizes {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/womdaybox/bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Manrope;
}
.prizes_main {
    width: 757px;
    position: relative;

    border: 1px solid #F0D9D7;
    border-radius: 24px;
}
.prizes_main:has(.prizes_ghost_title) {
    border: none;
}
.prizes_block {
    display: flex;
    position: relative;
    z-index: 3;
    background-color: #E7F5E1;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.prizes_item {
    cursor: pointer;
}
.prizes_item img {
    margin: 0 13px;
    transform: scale(.8);
    transition: .2s ease-in-out;
}
.prizes_item:hover img {
    transform: scale(.9);
}
.prizes_item__active {
    pointer-events: none;
}

.prizes_ghost_title {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.1;
    letter-spacing: 0.10rem;
    text-align: center;
}
.title1 {
    text-align: center;
    text-transform: uppercase;
    color: #A6C8C5;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 2px;
}
.title2 {
    text-transform: uppercase;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 45px;
    color: #E8507B;
    line-height: 1.1;
}
.title3 {
    color: #585656;
    font-size: 22px;
    line-height: 1.35;
}

.win {
    border-radius: 24px;
    background: #fff;
    margin: 0 auto;
    padding: 90px 60px;
}
.win::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 321px;
    height: 316px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/bant.png');
    pointer-events: none;
}
.win > * {
    position: relative;
}
.win_img {
    text-align: center;
    margin-top: -100px;
}
.win_img img {
    max-width: 200px;
}
.win_name {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: #312F2F;
    font-weight: 800;
    font-size: 45px;
    line-height: 1;
}
.win_desc {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 30px;
}
.win_more {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
}
.t1 {
    color: #44A56F;
    padding: 12px 16px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    border-radius: 57.077px;
    border: 1px solid #44A56F;
    backdrop-filter: blur(3.5673418045043945px);
    margin-bottom: 25px;
    width: max-content;
}
.prizes_hide_top {
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 40px 60px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.prizes_hide_top::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 249px;
    height: 294px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/womdaybox/prizes.png');
    pointer-events: all;
}
.prizes_hide_top > * {
    position: relative;
    z-index: 1;
}
.prizes_hide_pretitle {
    text-transform: uppercase;
    /* margin-bottom: 14px; */
    font-weight: bold;
    font-size: 40px;
    color: #E8507B;
    line-height: 1.1;
}
.prizes_hide_title {
    color: #312F2F;
    font-size: 18px;
    line-height: 1.4;
}
.prize_hide_images {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.prizes_hide_bottom {
    background-color: #E7F5E1;
    padding: 40px 60px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.prizes_hide_info {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}
.prizes_hide_info_number {
    width: 15px;
    height: 15px;
    background-color: #44A56F;
    border-radius: 100%;
    position: relative;
    top: 3px;
}
.prizes_hide_info_text {
    flex: 1;
    line-height: 1.4;
    color: #312F2F;
}
.prizes_hide_btns {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
}
.prizes_hide__btn {
    width: 309px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-radius: 20px;
    background: linear-gradient(95deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0.00) 92.21%), #E8507B;
    box-shadow: 0px 2.617px 5.233px 0px #F0A3B8 inset, 0px -2.617px 2.617px 0px #DF527A inset, 0px 7.135px 24.8px 0px rgba(232, 80, 123, 0.17);
    cursor: pointer;
    position: relative;
    padding: 0 25px;
    text-align: center;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
}
.prizes_hide__btn:hover {
    transform: translateY(-4px);
}
.prizes_already {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 24px;
    padding-bottom: 0;
}
.prizes_already_title {
    font-weight: 800;
    margin-bottom: 20px;
    color: #111010;
    font-size: 36px;
    line-height: 1.15;
    text-align: center;
    text-transform: uppercase;
}
.prizes_already_text {
    font-family: Inter;
    font-weight: 500;
    color: #C1C1C1;
    font-size: 22px;
    line-height: 1.35;
    text-align: center;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loader_block {
    display: flex;
    justify-content: center;
}
.loader div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff2478;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.loader div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.loader div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.loader div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}

.ucan {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
.prizes_hide .ucan {
    filter: grayscale(1);
}
.promo_img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: max-content;
}
.promo_img p {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.35;
    text-align: center;
    border-radius: 10px;
    background: rgba(13, 29, 25, 0.79);
    width: max-content;
    padding: 4px 21px;
    position: relative;
    z-index: 2;
    color: #fff;
    transition: .3s ease-in-out;
}
.promo_img_1 {
    margin-left: 357px;
    margin-top: 80px;
}
.promo_img_2 {
    margin-left: -695px;
    margin-top: -122px;
}
.promo_img_6 {
    margin-left: -625px;
    margin-top: -410px;
}
.promo_img_7 {
    margin-left: 380px;
    margin-top: -340px;
}
.promo_img_8 {
    margin-left: -632px;
    margin-top: 151px;
}
.promo_img_1 p {
    margin-top: -75px;
    margin-left: 10px;
}
.promo_img_2 p {
    margin-top: -145px;
    margin-left: 20px;
}
.promo_img_6 p {
    margin-top: -32px;
    margin-left: 32px;
}
.promo_img_7 p {
    margin-top: -43px;
    margin-left: -30px;
}
.promo_img_8 p {
    margin-top: -78px;
    margin-left: 15px;
}

@media(max-width: 1599px) {
    .promo_img {
        transform: scale(0.8);
    }
    .promo_img_8 {
        display: none;
    }
}
@media(max-width: 1199px) {
    .ucan {
        display: none;
    }
}
  
@media(max-width: 767px) {
    .prizes_main {
        width: 100%;
        border: none;
    }
    .title1 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        margin-bottom: 16px;
    }
    .title2 {
        font-size: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .title3 {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
    }
    .title3 br {
        display: none;
    }
    .prizes_ghost_title {
        font-size: 18px;
        padding: 0 10px;
    }
    .win {
        padding: 30px 15px;
        margin-left: 15px;
        margin-bottom: 40px;
    }
    .win::before {
        content: none;
    }
    .win_name {
        font-size: 32px;
    }
    .win_img img {
        max-width: 150px;
    }
    .win_desc {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .win_more {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 0;
    }
    .prizes_block {
        /* flex-direction: column; */
        align-items: center;
    }
    .prizes_item {
        margin-bottom: 0;
    }
    .prizes_already_text {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        margin-bottom: 22px;
    }
    .prizes_already_title {
        font-size: 24px;
        margin-bottom: 18px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .prizes_hide {
        margin: 40px 15px;
    }
    .prizes_hide_top::before {
        content: none;
    }
    .prizes_hide_top, .prizes_hide_bottom {
        padding: 20px;
    }
    .t1 {
        font-size: 20px;
        margin: 0 auto 20px;
    }
    .prizes_hide_info_number {
        font-size: 30px;
    }
    .prizes_hide_pretitle {
        font-size: 28px;
        text-align: center;
    }
    .prizes_hide_title {
        font-size: 18px;
        text-align: center;
    }
    .prize_hide_images {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .prize_hide_images img {
        max-width: 155px;
    }
    .prize_hide_images img:nth-of-type(1) {
        margin-right: -20px;
    }
    .prize_hide_images img:nth-of-type(2) {
        margin-left: -20px;
    }
    .prize_hide_images img:nth-of-type(3) {
        margin-top: -110px;
    }
    .prizes_hide_btns img {
        display: none!important;
    }
    .prizes_hide__btn {
        width: 100%;
        font-size: 18px;
        text-align: center;
    }
    .prizes_item img {
        max-width: 100px;
        margin: 0;
    }
}