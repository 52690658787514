.preims {
    padding: 0 0 50px;
    overflow: hidden;
}
.preims_pretitle {
    margin-bottom: 16px;
    text-align: center;
    padding: 0 15px;
    color: #A1A1A1;
    font-size: 22px;
    line-height: 1.3;
}
.preims_title {
    text-align: center;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 48px;
    line-height: 1.3;
    margin-bottom: 35px;
    background: linear-gradient(1deg, rgba(252, 252, 252, 0.00) -23.42%, #FCFCFC 49.33%, rgba(252, 252, 252, 0.00) 119.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 15px;
}
.students_row {
    display: flex;
    width: 10000px;
    gap: 20px;
    position: relative;
    overflow: hidden;
}
.students_row1 {
    /* margin-bottom: 90px; */
    animation: linemove 305s linear infinite;
}
.students_item {
    flex: 0 1 300px;
    max-width: 300px;
    cursor: pointer;
}
.students_item img {
    border-radius: 12px;
}
@keyframes linemove {
    0% {
        transform: translateX(-5120px);
    }
    100% {
        transform: translateX(0);
    }
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.79);
    backdrop-filter: blur(8px);
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    z-index: 99;
    transition: .3s ease-in-out;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal_active {
    opacity: 1;
    pointer-events: all;
}
.close_btn {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #2E2E2F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;
    transition: .3s ease-in-out;
}
.close_btn svg {
    width: 16px;
    height: 17px;
}
.close_btn svg path {
    transition: .3s ease-in-out;
}
.close_btn:hover {
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -26.64%, rgba(255, 255, 255, 0.10) 37.88%, rgba(255, 255, 255, 0.00) 78.59%), #3172D3;
}
.modal_img {
    max-width: 560px;
    margin: 0 auto;
    transform: scale(0);
    transition: .3s ease-in-out;
}
.modal_active .modal_img {
    transform: scale(1);
}

.title2 {
    font-family: Nface;
    margin-bottom: 45px;
    text-align: center;
    text-transform: uppercase;
    font-size: 35px;
    line-height: 1.3;
}
.title2 span {
    color: #8FA8E8;
}
.subtitle2 {
    margin-bottom: 50px;
    text-align: center;
    padding: 0 15px;
    font-size: 20px;
    color: #565656;
    line-height: 1.3;
}
.block2 {
    display: flex;
    gap: 35px;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    /* max-width: 880px; */
    margin: 0px auto;
}
.item2 {
    position: relative;
    /* max-width: 220px; */
}
.block2_items {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: -100px;
    z-index: 1;
    flex-wrap: wrap;
    display: none!important;
}
.item3:nth-of-type(1) {
    max-width: 280px;
}
.item3:nth-of-type(2) {
    max-width: 481px;
    display: flex;
    gap: 20px;
}
.item3:nth-of-type(3) {
    max-width: 481px;
    border-color: #FF84A9;
    background-color: #FF84A9;

}
.item3:nth-of-type(3) p {
    color: #fff;
}
.item3:nth-of-type(4) {
    max-width: 280px;
}
.block3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 910px;
    padding: 0 15px;
    margin: -100px auto 0;
    z-index: 1;
    position: relative;
}
.item3 {
    border: 1px solid #fff;
    background-color: #fff;
    padding: 20px;
    border-radius: 16px;
    max-height: 150px;
}
.item3 p {
    line-height: 1.4;
    color: #4D5C6B;
}
.item3 p span {
    font-weight: 600;
    color: #25394C;
}

.item3 img {
    margin-bottom: 12px;
}
.gfhgf {
    display: none!important;
}
.promo_remains {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 40px;
}
.promo_remains_1 {
    font-size: 18px;
    line-height: 1.25;
}
.promo_remains_1 b {
    display: block;
}
.promo_remains_2 {
    margin-bottom: 3px;
    color: #FF84A9;
    font-family: Nface;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -0.478px;
}
.ustr {
    display: flex;
    justify-content: space-between;
    max-width: 1024px;
    margin: 0 auto 45px;
}
.ustr_item {
    text-align: center;
}
.ustr_item p {
    padding-top: 20px;
    color: rgba(105, 99, 99, 0.8);
    
}

@media(max-width: 575px) {
    .ustr {
        flex-wrap: wrap;
        margin-bottom: 0;
        justify-content: center;
    }
    .ustr_item {
        flex: 0 1 40%;
        max-width: 40%;
        margin-bottom: 30px;
    }
    .ustr_item img {
        height: 60px;
    }
    .promo_remains {
        gap: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    .promo_remains br {
        display: block!important;
    }
    .promo_remains_2 {  
        text-align: left;
        font-size: 42px;
        margin-bottom: 0;
        order: 2;
    }
    .item2 img {
        max-height: 414px;
    }
    .item3 {
        flex: 0 1 100%!important;
        max-width: 100%!important;
        flex-direction: column!important;
        align-items: flex-start!important;
    }
    .modal {
        padding: 35px 15px;
    }
    .preims {
        padding: 40px 0;
        background-color: #F4F4F4;
        background-position: 50% 0%;
    }
    .preims_title {
        font-size: 22px;
    }
    .preims_pretitle {
        font-size: 18px;
    }
    .title2 {
        font-size: 22px;
        padding: 0 15px;
        margin-bottom: 25px;
    }
    .subtitle2 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .students_row1 {
        /* margin-bottom: 40px; */
    }
    .item3:nth-of-type(1) {
        box-shadow: 0px 26px 11px 56px #F4F4F4;
    }

    .item2 {
        display: none;
    }
    .item2:nth-of-type(1) {
        display: block;
        text-align: center;
    }
    .block2 {
        flex-direction: column;
        gap: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .item3 {
        position: relative;
        top: 0!important;
        left: 0!important;
        margin-left: 0!important;
        max-height: max-content;
    }
    .block2_items {
        margin-top: -40px;
        display: flex!important;
    }
    .item3:nth-of-type(2) img{
        position: absolute;
        opacity: 0;
    }
    .gfhgf {
        display: flex!important;
    }
    .item2 img {
        display: none!important;
    }
}