.author {
    padding: 50px 0 139px;
}
.author_title {
    margin-bottom: 6px;
    text-align: center;
    text-transform: uppercase;
    font-family: InterT;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
}
.author_subtitle {
    text-align: left;
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 1.4;
    color: #282828;
    margin-bottom: 40px;
}
.author_subtitle b {
    color: #282828;
    font-weight: 600;
}
.author_block {
    position: relative;
    /* text-align: center; */
    /* margin: -40px 0; */
    display: flex;
    margin-bottom: 60px;
    gap: 47px;
}
.author_block_info_title {
    font-family: InterT;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 10px;
}
.author_block_info_title span {
    color: #5AA675;
}
.author_block_info_subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    color: #282828;
}
.author_block_info ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 34px;
}
.author_block_info ul li {
    position: relative;
    padding-left: 36px;
    font-size: 18px;
    line-height: 1.4;
}
.author_block_info ul li b {
    font-weight: 600;
}
.author_block_info ul li::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #5AA675;
    border-radius: 100%;
    top: 6px;
    left: 0;
}
.author_block_info_last {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.4;
}
/* .author_block div {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    line-height: 1.35;
}
.author_block div:nth-of-type(1) {
    margin-left: -430px;
    margin-top: -280px;
    text-align: right;
} */
/* .author_block div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 239px;
    height: 29px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="239" height="29" viewBox="0 0 239 29" fill="none"><path d="M219.48 1L219.897 0.724346L219.749 0.5H219.48V1ZM233.333 26C233.333 27.4728 234.527 28.6667 236 28.6667C237.473 28.6667 238.667 27.4728 238.667 26C238.667 24.5272 237.473 23.3333 236 23.3333C234.527 23.3333 233.333 24.5272 233.333 26ZM0 1.5H219.48V0.5H0V1.5ZM219.063 1.27565L235.583 26.2757L236.417 25.7243L219.897 0.724346L219.063 1.27565Z" fill="%23FF2E5B"/></svg>');
}
.author_block div:nth-of-type(2) {
    margin-left: 200px;
    margin-top: -280px;
    text-align: left;
}
.author_block div:nth-of-type(2)::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 217px;
    height: 29px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="217" height="29" viewBox="0 0 217 29" fill="none"><path d="M19.52 1L19.1028 0.724347L19.2511 0.5H19.52V1ZM5.66667 26C5.66667 27.4728 4.47276 28.6667 3 28.6667C1.52724 28.6667 0.333328 27.4728 0.333328 26C0.333328 24.5272 1.52724 23.3333 3 23.3333C4.47276 23.3333 5.66667 24.5272 5.66667 26ZM217 1.5H19.52V0.5H217V1.5ZM19.9371 1.27565L3.41714 26.2757L2.58286 25.7243L19.1028 0.724347L19.9371 1.27565Z" fill="%23FF2E5B"/></svg>');
}
.author_block div:nth-of-type(3) {
    margin-left: -555px;
    margin-top: -75px;
    text-align: right;
}
.author_block div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 241px;
    height: 29px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="241" height="29" viewBox="0 0 241 29" fill="none"><path d="M221.69 1L222.109 0.7268L221.961 0.5H221.69V1ZM235.333 26C235.333 27.4728 236.527 28.6667 238 28.6667C239.473 28.6667 240.667 27.4728 240.667 26C240.667 24.5272 239.473 23.3333 238 23.3333C236.527 23.3333 235.333 24.5272 235.333 26ZM0 1.5H221.69V0.5H0V1.5ZM221.271 1.2732L237.581 26.2732L238.419 25.7268L222.109 0.7268L221.271 1.2732Z" fill="%23FF2E5B"/></svg>');
}
.author_block div:nth-of-type(4) {
    margin-left: 310px;
    margin-top: -50px;
    text-align: left;
}
.author_block div:nth-of-type(4)::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 214px;
    height: 29px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="214" height="29" viewBox="0 0 214 29" fill="none"><path d="M19.52 1L19.1028 0.724347L19.2511 0.5H19.52V1ZM5.66667 26C5.66667 27.4728 4.47276 28.6667 3 28.6667C1.52724 28.6667 0.333328 27.4728 0.333328 26C0.333328 24.5272 1.52724 23.3333 3 23.3333C4.47276 23.3333 5.66667 24.5272 5.66667 26ZM214 1.5H19.52V0.5H214V1.5ZM19.9371 1.27565L3.41714 26.2757L2.58286 25.7243L19.1028 0.724347L19.9371 1.27565Z" fill="%23FF2E5B"/></svg>');
} */
.author_info {
    display: flex;
    justify-content: space-between;
}
.author_info_item {
    max-width: 196px;
}
.author_info_item p {
    color: #5AA675;
    font-family: InterT;
    font-size: 36px;
    line-height: 1.1;
}
.author_info_item span {
    display: block;
    line-height: 1.3;
}
.author_block_info img {
    display: none!important;
}
@media(max-width:575px) {
    .br1 {
        display: block !important;
    }
    .author {
        padding: 50px 0 50px;
    }
    .author_title {
        margin-bottom: 16px;
        font-size: 26px;
    }
    .author_subtitle {
        margin-bottom: 15px;
        font-size: 18px;
        /* text-align: center; */
    }
    .author_block {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .author_block img {
        /* margin-bottom: -40px; */
        /* margin-top: 30px;
         */
        order: 2;
        display: none!important;
    }
    .author_block_info img {
        display: block!important;
        margin-bottom: 16px;
    }
    .author_block_info_title {
        font-size: 24px;
        /* text-align: center; */
    }
    .author_block_info_subtitle {
        font-size: 16px;
    }
    .author_block_info ul {
        margin-bottom: 0;
    }
    .author_block_info ul li {
        font-size: 16px;
        padding-left: 20px;
    }
    .author_block_info_last {
        font-size: 20px;
    }
    .author_block div {
        /* position: relative!important;
        left: 0!important;
        top: 0!important;
        margin-left: 0!important;
        margin-top: 0!important;
        font-size: 18px!important;
        line-height: 1.5!important;
        padding-bottom: 10px!important;
        margin-bottom: 10px!important;
        border-bottom: 1px solid #FF2E5B!important;
        text-align: left!important; */
    }
    .author_block div::before {
        content: none!important;
    }
    .author_info {
        gap: 10px;
        flex-wrap: wrap;
    }
    .author_info_item {
        flex: 0 1 calc(50% - 5px);
        max-width: calc(50% - 5px);
    }
    .author_info_item p {
        font-size: 32px;
    }
    .author_info_item span {
        font-size: 14px;
    }
}