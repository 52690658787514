.promo {
    padding-top: 93px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/feb23/promo-bg4.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 701px;
}
.main {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.promo_right {
    max-width: 687px;
    display: flex;
    flex-direction: column;
    margin-right: -145px;
}
.promo_pretitle {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-size: 21px;
    font-weight: 500;
    line-height: 1.47;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #797F84;
    background-color: #EBF0EE;
    border-radius: 30px;
    padding: 10px 20px;
    width: max-content;
    margin-bottom: 35px;
    
}
.promo_pretitle img {
    margin-top: -3px;
    margin-right: 11px;
    height: 100%;
}
.promo_title {
    font-family: InterT;
    font-size: 45px;
    line-height: 1.1;
    color: #282828;
    position: relative;
    margin-bottom: 20px;
}
.promo_title span {
    display: inline-block;
    color: #5AA675;
}
.sale {
    display: inline-block;
    width: 135px;
    height: 93px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/tik2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    margin-left: 15px;
}
.sale p {
    position: absolute;
    top: 26px;
    left: 17px;
    transform: rotate(342deg);
    font-family: 'Inter';
    font-size: 38px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: #fff;
}
.promo_subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    font-family: Inter;
    letter-spacing: -0.02em;
    color: #282828CC;
    margin-bottom: 50px;
}
.promo_bottom_block {
    display: flex;
    gap: 25px;
}
.prize {
    display: flex;
    padding: 16px 30px;
    gap: 22px;
    background-color: #ECECEC;
    border-radius: 30px;
    align-items: center;
    justify-content: flex-start;
}
.prize_text {
    font-family: 'Inter';
    font-size: 32px;
    font-weight: 400;
    line-height: 1.25;
    color: #707B96;
}
.prize_text span {
    color: #FE5858;
}
.prize_text b {
    font-weight: 600;
}
.buttons {
    display: flex;
    gap: 13px;
}
.btn {
    position: relative;
    transition: .2s ease-in-out;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    text-align: center;
    width: 275px;
    padding: 25px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.36deg, #54AC3B -50.46%, #23845D 99.6%),#FFFFFF33;
    border-radius: 60px;
    cursor: pointer;
    margin-bottom: 35px;
}
.btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #FFFFFF33;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn2 {
    background: #fff;
    color: #5AA675;
    border: solid 1px #5AA675;
}
.promo_discount {
    font-family: Inter;
    font-size: 27px;
    font-weight: 500;
    line-height: 1.2;
}
.count span{
    color: #5AA675;
    font-weight: 500;
}
.left_1 {
    margin-top: 7px;
    font-weight: 500;
}
.left_1 span {
    color: #5AA675;
}

.countdown {
    background-color: #2F8FD5;
    border-radius: 20px;
    padding: 23px 32px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    max-width: max-content;
    gap: 20px;
    max-width: 420px;
    width: 100%;
    margin: 0 auto 40px; 
}
.countdown > p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 1.3;
}
.countdown_timer {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.countdown_timer > div {
    display: flex;
    justify-content: flex-start;
    color: #5AA675;
    font-weight: 600;
    font-size: 56px;
}
.countdown_timer span {
    margin-left: -9px;
    margin-right: 2px;
    position: relative;
    top: -3px;
}
.countdown_timer_text p{
    color: #000;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
}
.promo_mob_img {
    display: none !important;
    height: auto;
}
.promo_subtitle b {
    font-weight: 600;
}
@media(max-width:575px) {
    .promo {
        background-image: none;
        height: auto;
        padding-top: 40px;
        padding-bottom: 130px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #F9FEFF;
        margin-top: -20px;
    }
    .img_shadow {
        position: relative;
    }
  
    .promo_mob_img {
        position: relative;
        display: block !important;
        height: auto;
        margin-left: -20px;
        width: calc(100% + 40px);
        margin-top: 2px;
    }
    .promo_mob_img::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        box-shadow: 0px 0px 5px 6px #F9FEFF;
    }
    .main {
        display: block;
    }
    .promo_pretitle {
        font-size: 12px;
        margin-bottom: 8px;
        width: 100%;
        padding: 8px;
    }
    .promo_title {
        margin-bottom: 10px;
        font-size: 28px;    
        line-height: 1.12;
        letter-spacing: 0.823px;
    }
    .sale {
        width: 73px;
        height: 48px;
    }
    .countdown_timer > div {
        font-size: 38px;
    }
    .sale p {
        font-size: 18px;
        top: 13px;
        left: 14px;
    }
    .countdown_timer {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .promo_subtitle {
        font-size: 16px;
        margin-bottom: 0px;   
    }
    .promo_right {
        margin: 0px;
        gap: 0;
    }
    .prize {
        align-items: flex-start;
        padding: 14px;
        gap: 35px;
        margin-bottom: 15px;
    }
    .prize img {
        max-width: 79px;
    }
    .prize_text {
        font-size: 18px;
    }
    .buttons {
        flex-direction: column;
        margin-bottom: 21px;
        margin-top: -55px;
    }
    .btn {
        font-size: 18px;
        width: 100%;
        height: 60px;
        margin-bottom: 0px;
    }
    .promo_discount {
        font-size: 20px;
        width: max-content;
        margin: 0 auto;
        text-align: center;
    }
}
@media(max-width: 374px) {
    .promo_title {
        font-size: 28px;
    }
    .promo_pretitle {
        font-size: 16px;
        padding: 0px;
    }
    .btn {
        font-size: 18px;
    }
    .promo_discount {
        font-size: 18px;
        text-align: center;
        
    }
}