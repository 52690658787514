.studentres {
    overflow: hidden;
    position: relative;
    padding: 50px 0 0px;
    background-color: #FFF9F5;
}
.pretitle {
    text-align: center;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 1.4;
}
.pretitle b {
    font-weight: 600;
    color: #fff;
}
.title {
    text-align: center;
    /* text-transform: uppercase; */
    margin-bottom: 5px;
    font-size: 44px;
    line-height: 1.1;
    color: #14040A;
    font-weight: 600;
    text-transform: uppercase;
}
.title span {
  color: #E8507B;
}
.subtitle {
    margin-bottom: 42px;
    text-align: center;
    color: #585656;
    font-size: 20px;
    line-height: 1.4;
}
.slider_block {
    max-width: 1660px;
    padding: 0px 15px;
    margin: 0 auto;
    position: relative;
}
.slider_nav {
    display: flex;
    gap: 16px;
    position: absolute;
    top: 50%;
    width: calc(100% + 140px);
    justify-content: space-between;
    margin-top: -80px;
    left: -70px;
}
.slider_btn_prev {
    background-color: #E8507B;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next {
    background-color: #E8507B;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next img,
.slider_btn_prev img {
    /* filter: brightness(0); */
}
.slider {
    margin-bottom: 45px;
}
.slide {
    border: 1px solid #E8E8E8;
    border-radius: 38px;
    background-color: #fff;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 15px;
    color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slide_info div img {
    /* filter: brightness(0.1); */
    filter: hue-rotate(119deg);
    margin-top: -2px;
    margin-right: 2px;
}
.slider .slider_nav {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
}
.slide_img {
    position: relative;
}
.slide_img > p {
    position: absolute;
    left: 18px;
    bottom: 18px;
    border-radius: 55.051px;
    background: #44A56F;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    padding: 4px 14px;
}
.slide_img > div {
    position: absolute;
    right: 18px;
    bottom: 18px;
}
.btn {
    position: relative;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 399px;
    height: 100px;
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(0deg, #F97193, #F97193), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px -2.62px 2.62px 0px #E6476E inset;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.btns {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}
.promo_btn {
  width: 394px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 20px;
  background: linear-gradient(0deg, var(--green, #44A56F), var(--green, #44A56F)), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
  box-shadow: 0px 7.134683609008789px 39.24075698852539px 0px #39A5AE26;
  cursor: pointer;
  position: relative;
  padding: 0 25px;
  text-align: center;
  transition: .3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}
/* .promo_btn::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #FFFFFF33;
} */
.promo_btn:hover {
  transform: translateY(-4px);
}
.promo_discount {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  margin: 20px auto 0px;
  width: max-content;
  text-align: center;
}
.count {
  font-size: 36px;
}
.count span{
  color: #E8507B;
  font-weight: 600;
}
.left_1 {
  margin-top: 7px;
  font-weight: 500;
  text-align: center;
  color: #E8507B;
}
.students_countdown {
  display: flex;
  gap: 0;
  align-items: center;
  justify-content: center;
  min-width: 280px;
  margin-top: 20px;
  flex-direction: column;
}
.students_countdown > p {
  font-size: 22px;
  color: rgba(40, 40, 40, 0.8);
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
}
.students_countdown div {
  display: flex;
  justify-content: flex-start;
  color: #E8507B;
  font-weight: 600;
  font-size: 56px;
}
.btns > p {
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  position: relative;
}
.btns > p span {
  position: absolute;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 28px;
  align-items: center;
  transform: rotate(340deg);
}
.btns > p::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/womens_day/tiket.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
    .btns > p {
      display: none;
    }
    .studentres {
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .pretitle {
      font-size: 18px;
    }
    .title {
      font-size: 24px;
      text-align: center;
    }
    .title br{
      display: block !important;
    }
    .slider {
      margin: 0 0 30px;
    }
    .btn {
      width: 100%;
      font-size: 18px;
      height: 78px;
      padding: 10px;
    }
    .slide_info div {
      font-size: 13px;
    }
    .slider .slider_nav {
      display: flex;
    }
    .slider_nav {
      position: absolute;
      top: 50%;
      padding: 0 10px;
      left: 0;
      z-index: 2;
      width: 100%;
      justify-content: space-between;
    }
    .slider_btn_next {
      width: 42px;
      height: 42px;
    }
    .slider_btn_prev {
      width: 42px;
      height: 42px;
    }
    .slide_info {
      padding: 17px 8px;
    }
    .title span {
      padding: 0;
      border: none;
    }
    .btns {
      flex-direction: column;
      gap: 10px;
  }
  .promo_btn {
    width: 100%;
    font-size: 16px;
    padding: 25px 20px;
    text-align: center;
    height: 60px;
}
.count {
  font-size: 24px;
}
.count span {
  display: block;
}
.promo_discount {
    font-size: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.students_countdown {
  flex-direction: column;
}
.students_countdown > p {
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
}
.students_countdown div {
    justify-content: center;
    font-size: 40px;
}
.left {
  font-size: 18px;
}
  }
  