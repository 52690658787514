.gifts {
    padding: 100px 0 50px;
    background-color: #FFF9F5;
}
.block_pretitle {
    padding: 4px 19px;
    /* border-radius: 10px; */
    /* background: #F7E6E6; */
    /* text-transform: uppercase; */
    color: #E8507B;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 1.18px;
    max-width: max-content;
    margin: 0 auto 12px;
}
.gifts_title {
    margin-bottom: 12px;
    /* text-transform: uppercase; */
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.15;
    color: #111010;
}
.gifts_title span {
    color: #E8507B;
}
.gifts_title .old_price {
    text-decoration: line-through;
    color: #dedede;
}
.gifts_subtitle {
    text-align: center;
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 40px;
}
.gifts_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 820px;
    margin: 0px auto 40px;
}
.gifts_item {
    box-shadow: 0px 4px 12px -2px #10182805;
    border-radius: 20px;
    background-color: #FFF9F5;
    flex: 0 1 248px;
    max-width: 248px;
    display: flex;
    flex-direction: column;
    transition: .3s ease-in-out;
}
.gifts_item:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.gifts_item_img img {
    border-radius: 20px;
}
.gifts_item_info {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* gap: 8px; */
    height: 100%;
}
.gifts_item_info p {
    line-height: 1.3;
}
.gifts_item_info p b {
    font-weight: 600;
}
.gifts_item_info span {
    font-size: 18px;
    color: #E8507B;
    font-weight: 500;
    text-decoration: line-through;
}
.promo_btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.promo_btn {
    position: relative;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 399px;
    height: 100px;
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(0deg, #F97193, #F97193), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px -2.62px 2.62px 0px #E6476E inset;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.promo_btn p {
    position: relative;
    z-index: 1;
}
.promo_countdown {
    display: flex;
    gap: 4px;
    font-size: 20px;
    line-height: 1.3;
    flex-direction: column;
}
.promo_countdown p {
    font-weight: 500;
    color: #585656;
}
.promo_countdown p span {
    text-decoration: line-through;
}
.promo_countdown_timer > div {
    display: flex;
    justify-content: center;
}
.promo_countdown_timer > div div {
    font-weight: 600;
    display: flex;
    color: #8A883E;
    font-size: 44px;
}
.promo_countdown_timer > div div span {
    margin-left: 0;
}
.br_mob {
    display: none !important;
}
@media(max-width: 575px) {
    .br_mob {
        display: block !important;
    }
    .promo_btns {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
    .promo_btn {
        width: 100%;
        height: 60px;
        display: flex;
        font-size: 16px;
        margin-bottom: 0px;
    }
    .promo_remain {
        text-align: center;
        margin: 0px auto;
    }
    .promo_block {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }
    .promo_countdown {
        margin-bottom: 16px;
        margin-top: 16px;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    .promo_countdown > div div {
        font-size: 36px;
    }
    .gifts {
        padding: 40px 0 40px;
        margin-bottom: -4px;
    }
    .block_pretitle {
        font-size: 18px;
        margin-bottom: 18px;
        margin-left: 0px;
        padding-left: 0px;
    }
    .gifts_pretitle {
        font-size: 18px;
        margin-bottom: 8px;
    }
    .gifts_title {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: left;
    }
    .gifts_subtitle {
        font-size: 18px;
        margin-bottom: 36px;
        text-align: left;
    }
    .gifts_item_info p {
        font-size: 16px;
    }
    .gifts_block {
        gap: 10px;
        margin-bottom: 0px;
    }
    .gifts_item {
        min-height: 145px;
    }
}