.programs {
    padding: 10px 0 120px;
    position: relative;
    background-color: #FFF9F5;
}
@media (min-width:1250px){.container{max-width:1200px}}
.t1 {
    text-align: center;
    margin-bottom: 25px;
    font-family: 'Manrope';
    font-size: 40px;
    text-transform: uppercase;      
    display: flex;
    gap: 30px;
    justify-content: center;
    font-weight: 600;
    line-height: 1.1;
    color: #473622;
}
.t1 span {
    color: #F97193;
}
.st1 {
    color: #473622;
    text-align: center;
    margin-bottom: 30px;
    font-size: 21px;
    line-height: 1.3;
}
.st1 b {
    font-weight: 400;
}
.dts {
    display: flex;
    justify-content: center;
    gap: 19px;
    margin-bottom: 50px;
}
.dts_item {
    border-radius: 60px;
    background: #F5ECE1;
    box-shadow: 0px 4px 20px 0px rgba(13, 16, 26, 0.02);
    padding: 8px 20px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    max-width: max-content;
}
.dts_item_text {
    font-size: 20px;
    line-height: 1.1;
    color: #473622;
    font-weight: 400;
}
.programs_block {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    margin: 0 auto 50px;
    max-width: 1254px;
    padding: 0 15px;
}
.programs_block_cours {
    max-width: 1620px;
}

.programs_item {    
    max-width: 362px;
}
.programs_info {
    border-radius: 20px;
    border: 1px solid #FFF9F5;
    background: #F5ECE1;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s ease-in-out;
}
.programs_info:hover {
    transform: scale3d(1.03, 1.03, 1.03);
}
.programs_img {
    position: relative;
    text-align: right;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #F5ECE1;
    height: 224px;
}
.programs_img img {
    pointer-events: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.programs_img__info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.programs_tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: auto;
}
.programs_tag {
    padding: 0 8px;
    color: #fff;
    background-color: #F97193;
    border-radius: 19.333px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    line-height: 2;
}
.programs_tag_new {
    color: #2B2B2B;
    background-color: #FF5C00;
}
.programs_img__info_title {
    letter-spacing: 0.03rem;
    color: #2B2B2B;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
}
.programs_img__info_title span {
    display: block;
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: 0;
    font-weight: 700;
}
.programs_img__info_price {
    position: relative;
    max-width: max-content;
    color: #473622;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    line-height: normal;
    font-weight: 400;
}
.programs_img__info_price span {
    font-family: Manrope;
}
.programs_img__info_price::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    height: 3px;
    width: 100%;
    background-color: #F97193;
}
.programs_text {
    padding: 16px 20px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.programs_text p {
    margin-bottom: auto;
}
.programs_text ul {
    padding-bottom: 20px;
    margin-bottom: auto;
}
.programs_text ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.54;
    color: #473622;
    margin: 0px 0 6px;
    font-family: 'Inter';
}
.programs_text ul li b {
    font-weight: 400;
    color: #473622;
}
.programs_more {
    padding: 15px 0 2px;
}
.programs_more input {
    display: none;
}
.programs_more label {
    position: relative;
    width: 100%;
    display: block;
    /* text-transform: uppercase; */
    color: #2B2B2B;
    letter-spacing: 0.015rem;
    font-family: 'Manrope';
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
}
.programs_more label::before {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 14px;
    height: 2px;
    background-color: #F97193;
}
.programs_more label::after {
    content: '';
    position: absolute;
    right: 6px;
    top: 2px;
    width: 2px;
    height: 14px;
    background-color: #F97193;
}
.programs_more p, .programs_more label div {
    opacity: 0;
    visibility: hidden;
    height: 0;
    font-weight: 500;
    color: #473622;
    font-size: 16px;
    line-height: 1.2;
    padding-top: 12px;
    font-family: Manrope;
    text-transform: initial;
    transition: .3s ease-in-out;
}
.programs_more p b {
    font-weight: 400;
    color: #473622;
}
.programs_more label div ul {
    padding-bottom: 0;
}
.programs_more label div ul li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 3px;
    position: relative;
    padding-left: 15px;
}
.programs_more label div ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: #F97193;
    border-radius: 100%;
}
.programs_more input[type=checkbox]:checked + label p, 
.programs_more input[type=checkbox]:checked + label div {
    height: auto;
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
}
.programs_more input[type=checkbox]:checked + label::after {
    opacity: 0;
}
.programs_btn {
    width: 394px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-radius: 20px;
    background: linear-gradient(0deg, #D119FF, #D119FF), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px 4px 25.5px 0px #FD8C308F;    cursor: pointer;
    position: relative;
    padding: 0 25px;
    text-align: center;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    margin: 0 auto;
}
.programs_btn:hover {
    background: linear-gradient(0deg, #D940FF, #D940FF), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
    box-shadow: 0px 4px 25.5px 0px #FD8C308F;    transform: translateY(-3px);
}
.programs_btn:active {
    background: linear-gradient(0deg, #C817F4, #C817F4), linear-gradient(95.47deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0) 92.21%);
}
.btns {
    margin-top: 30px;
    display: flex;
    gap: 28px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    flex-direction: column;
}
.promo_btn {
    max-width: 556px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope';
    padding: 29px 36px;
    border-radius: 117px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: 0.03rem;
    background: linear-gradient(0deg, #F97193, #F97193), linear-gradient(85.01deg, rgba(255, 255, 255, 0) 15.07%, rgba(255, 255, 255, 0.2) 148.09%);
    box-shadow: 0px 4px 25.5px 0px #2290F175;
    transition: .2s ease-in-out;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.btns_prices_top {
    display: flex;
    align-items: center;
    gap: 14px;
}
.btns_prices_top p {
    color: #EDA069;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.1;
}
.btns_prices_top span {
    color: #A29FA4;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.1;
    text-decoration: line-through;
}
.btns_prices_bottom {
    text-align: center;
    margin-bottom: 25px;
    font-family: Unisans;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
    color: #1B151F;
}
.pr1 {
    text-align: center;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 35px;
}
.pr1_1 {
    font-size: 40px;
    color: #111010;
    font-weight: 500;
    line-height: 1.2;
}
.pr1_2 {
    font-size: 28px;
    color: rgba(237, 48, 82, 0.35);
    font-weight: 500;
    line-height: 1.2;
    text-decoration: line-through;
}
.pr1_3 {
    position: relative;
}
.pr1_3 p {
    color: #fff;
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    top: 15px;
    left: 15px;
}
.pr2 {
    display: flex;
    gap: 35px;
    justify-content: center;
    padding: 0 15px;
    margin-bottom: 100px;
}
.pr2:last-of-type {
    margin-bottom: 0;
}
.pr3 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.pr3_1 {
    color: #131313;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.743px;
}
.pr3_2 {
    color: #131313;
    font-family: Bebas;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1.938px;
}

.programs_order {
    margin-top: 10px;
    cursor: pointer;
}
.programs_order_title {
    margin-bottom: 15px;
    /* color: #fff; */
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.24px;
}
.programs_order_prices {
    color: #F97193;
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    margin-top: 25px;
}
.programs_order_prices span {
    font-size: 14px;
    color: #747474;
    font-weight: normal;
    text-decoration: line-through;
    padding-left: 10px;
}
.programs_order_info {
    line-height: 1;
    padding-left: 29px;
    position: relative;
}
.programs_order_info::before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 21px;
    height: 21px;
    border: 1px solid #000;
    border-radius: 2px;
    transition: .2s ease-in-out;
    background-color: #fff;
}
.programs_order_info::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    top: 1px;
    border: 2px solid #F97193;
    left: 8px;
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity .2s ease-in-out;
}
.programs_order_active .programs_order_info::before {
    background-color: #fff;
}
.programs_order_active .programs_order_info::after {
    opacity: 1;
}
.prices_over_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    /* margin-bottom: 30px; */
    font-weight: 500;
    gap: 20px;
    color: #1B151F;
}
.prices_over_btn__price {
    color: #484343;
    
}
.prices_over_btn b {
    font-size: 40px;
    line-height: 1.2;
    color: #000;
}
.prices_over_btn span{
    font-size: 20px;
    font-weight: 500;
}
.prices_over_btn__old_price {
    color: #F97193;
    position: relative;
    font-size: 28px;
    font-weight: 500;
}
.prices_over_btn__old_price::before {
    content: '';
    position: absolute;
    bottom: 50%;
    border-bottom: solid 2px #F97193;
    width: 100%;
}
.prices_over_btn__discount {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #fff;
    position: relative;
}
.prices_over_btn__discount::before {
    content: '';
    position: absolute;
    /* left: 50%; */
    /* top: 50%; */
    margin-left: -30px;
    margin-top: -22px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 155px;
    height: 90px;
    background-image: url(https://gymteam.kinescopecdn.net/img/l//2025/womens_day/2/tiket.png);
}
.prices_over_btn__discount span {
    position: relative;
    display: inline-block;
    color: #fff;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 33px;
    transform: rotate(349deg);
}
.programs_countdown {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    min-width: 280px;
}
.programs_countdown > p {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    max-width: 121px;
}
.programs_countdown div {
    display: flex;
    gap: 2px;
    text-align: center;
    color: #F97193;
    font-family: Unisans;
    font-size: 70px;
    font-weight: 900;
    line-height: 1.1;
    text-align: left;
}

@media(max-width: 575px) {
    .programs {
        padding: 40px 0;
        /* border-radius: 0 0 40px 40px; */
    }
    .prices_over_btn {
        gap: 10px;
        font-size: 22px;
    }
    .prices_over_btn__old_price {
        font-size: 18px;
    }
    .programs_block {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-bottom: 0px;
    }
    .dts_item_text {
        font-size: 14px;
    }
    .programs_btn {
        width: 100%;
        height: 70px;
        text-align: center;
        font-size: 18px;
        margin-bottom: 0;
        line-height: 1.3;
    }
    .t1 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .st1 {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .dts {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
        align-items: center;
    }
    .pr1_1 {
        font-size: 36px;
    }
    .pr1_2 {
        font-size: 26px;
    }
    .pr1_3 {
        display: none;
    }
    .pr2 {
        flex-direction: column;
        gap: 16px;
    }
    .promo_btn {
        width: 100%;
        font-size: 16px;
        padding: 25px 20px;
        text-align: center;
    }
    .pr3 {
        flex-direction: row;
        gap: 16px;
    }
    .pr3_1 br {
        display: block!important;
    }
    .t1 span {
        font-size: 18px;
    }
    .dts_item {
        align-items: center;
    }
    .btns {
        flex-direction: column;
    }
    .prices_over_btn__discount::before {
        content: '';
        position: absolute;
        margin-left: -16px;
        margin-top: -15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 82px;
        height: 60px;
    }
    .promo_btn {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .btns_prices_bottom {
        text-align: center;
        margin-top: 4px;
        font-size: 20px;
    }
    .programs_countdown {
        flex-direction: column;
        gap: 5px;
    }
    .programs_countdown > p {
        max-width: 380px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
    }
    .programs_countdown div {
        justify-content: center;
        font-size: 40px;
    }
}
@media(max-width: 380px) {
    .programs_img__info_title span {
        margin-bottom: 6px;
        font-size: 20px;
    }
}