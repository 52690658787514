.students {
    overflow: hidden;
    position: relative;
    padding: 20px 0 80px;
}
.students_top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
}
.students_left {
    flex: 1;
}
.students_right {
    flex: 0 1 479px;
    max-width: 479px;
    text-align: right;
}
.students_pretitle {
    text-align: center;
    color: #676C72;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
}
.students_title {
    text-transform: uppercase;
    color: #333844;
    font-size: 44px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px;
}
.students_title span:nth-of-type(1) {
    color: #FF598B;
}
.students_title span:nth-of-type(2) {
    padding: 4px;
    border-radius: 18px;
    border: 1px solid #333844;
}
.students_btn {
    width: 479px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border-radius: 16px;
    background: linear-gradient(95deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0.00) 92.21%), #FF5C00;
    box-shadow: 0px 10px 32px 0px rgba(255, 94, 2, 0.35), 0px -2px 2px 0px #C14F0E inset, 0px 2px 4px 0px #FFB48A inset;
    cursor: pointer;
    position: relative;
    padding: 0 25px;
    text-align: center;
    transition: .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 14px;
}
.students_btn:hover {
    transform: translateY(-4px);
    background: linear-gradient(95deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0.00) 92.21%), #FF782D;
    box-shadow: 0px 10px 48px 0px rgba(255, 94, 2, 0.50), 0px -2px 2px 0px #C14F0E inset, 0px 2px 4px 0px #FFB48A inset;
}
.students_btn:active {
    background: linear-gradient(95deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0.00) 92.21%), #D24D03;
    box-shadow: 0px 10px 32px 0px rgba(255, 94, 2, 0.35), 0px -2px 2px 0px #C14F0E inset, 0px 2px 4px 0px #FFB48A inset;
}
.students_row {
    display: flex;
    width: 10000px;
    gap: 20px;
    position: relative;
    overflow: hidden;
}
.students_row1 {
    margin-bottom: 20px;
    animation: linemove 305s linear infinite;
}
.students_row2 {
    animation: linemove2 270s linear infinite;
}
.students_item {
    flex: 0 1 220px;
    max-width: 220px;
    cursor: pointer;
}
.students_item img {
    border-radius: 12px;
}
@keyframes linemove {
    0% {
        transform: translateX(-7200px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes linemove2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-7200px);
    }
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.79);
    backdrop-filter: blur(8px);
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    z-index: 99;
    transition: .3s ease-in-out;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal_active {
    opacity: 1;
    pointer-events: all;
}
.close_btn {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #2E2E2F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;
    transition: .3s ease-in-out;
}
.close_btn svg {
    width: 16px;
    height: 17px;
}
.close_btn svg path {
    transition: .3s ease-in-out;
}
.close_btn:hover {
    background: linear-gradient(98deg, rgba(255, 255, 255, 0.25) -16.4%, rgba(255, 255, 255, 0.10) 39.36%, rgba(255, 255, 255, 0.00) 74.55%), #EC6A67;
}
.modal_img {
    max-width: 560px;
    margin: 0 auto;
    transform: scale(0);
    transition: .3s ease-in-out;
}
.modal_active .modal_img {
    transform: scale(1);
}
.mob_fix {
    display: none;
}
.promo_btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 54px;
    background: linear-gradient(96deg, #FF77A0 44.96%, #EA6C92 147.95%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 35px;
    cursor: pointer;
    margin: 30px auto 0;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
@media(max-width: 575px) {
    .promo_btn {
        flex: 0 1 100%;
        max-width: 100%;
        width: calc(100% - 30px);
        padding: 12px 24px;
        font-size: 18px;
    }
    .students {
        padding: 20px 0 0;
    }
    .mob_fix {
        display: block;
    }
    .modal {
        padding: 35px 15px;
    }
    .students_left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .students_right {
        display: none;
    }
    .students_pretitle {
        text-align: center;
        margin-bottom: 16px;
        font-size: 20px;
    }
    .students_title {
        font-size: 38px;
        margin-bottom: 40px;
    }
    .students_top {
        margin-bottom: 0;
    }
    .mob_fix {
        margin-top: 30px;
        margin-bottom: 40px;
        padding: 0 10px;
    }
    .students_btn {
        width: 100%;
        font-size: 18px;
        padding: 0 10px;
        text-align: center;
        margin-bottom: 0;
        height: 72px;
    }
    .students_title {
        font-size: 26px;
    }
    .students_title span:nth-of-type(2) {
        display: inline-block!important;
    }
}