.promo {
    position: relative;
    padding: 105px 0 ;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/gymmarch/promo2.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 900px;
}
.promoblock {
    padding-left: 560px;
    position: relative;
}
.promo_pretitle {
    margin-bottom: 25px;
    color: #837F8B;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
}
.promo_title {
    font-family: Nface;
    font-size: 90px;
    letter-spacing: -3.6px;
    margin-bottom: 15px;
    line-height: normal;
    position: relative;
    width: max-content;
}
.promo_title::before {
    content: '';
    position: absolute;
    right: 0;
    top: 12px;
    width: 191px;
    height: 88px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/gymmarch/promo_title.png');
    background-size: cover;
}
.promo_subtitle {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.4;
}
.btns {
    display: flex;
    gap: 40px;
    margin-bottom: 25px;
    align-items: center;
}
.promo_btn {
    transition: .2s ease-in-out;
    width: 415px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-transform: uppercase;
    border-radius: 60px;
    background: #FF84A9;
    color: #fff;
    padding: 22px;
    cursor: pointer;
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
}
.promo_btn:hover {
    transform: translateY(-4px);
}
.promo_text {
    margin-bottom: 25px;
    color: #131313;
    font-size: 20px;
    line-height: 1.3;
}
.promo_text span {
    color: #FF724D;
}
.promo_next {
    margin-bottom: 0;
    color: #131313;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;
}
.promo_remains_1 {
    font-size: 18px;
    line-height: 1.25;
}
.promo_remains_1 b {
    display: block;
}
.promo_remains_2 {
    margin-bottom: 3px;
    color: #FF84A9;
    font-family: Nface;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -0.478px;
}
.promo_prize {
    position: absolute;
    left: 50%;
    bottom: 70px;
    margin-left: 370px;
    pointer-events: none;
}
.promo_prize_img {
    margin-bottom: -50px;
    margin-left: -15px;
}
.promo_prize_text {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    color: #ffff;
}
.mob_img,
.promo_remains_mob {
    display: none;
}
.promo_countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
}
.promo_countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FF84A9;
    font-size: 60px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
}
.promo_countdown_timer > div div {
    display: flex;
    gap: 3px;
}
.promo_imgs {
    display: none!important;
    /* position: absolute;
    right: -90px;
    bottom: -90px;
    pointer-events: none;
    display: none!important; */
}
.promo_imgs img:nth-of-type(1) {
    position: relative;
    z-index: 1;
    width: 100%;
}
.promo_imgs img:nth-of-type(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -260px;
    margin-top: -60px;
}
.st1 {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 0;
}
.st1 br {
    display: none !important;
}
.st1 b {
    font-weight: 600;
    display: block;
}
.st1 span {
    color: #8FA8E8;
}
.st2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
}
.promo_imgs img:nth-of-type(3) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 55px;
    margin-top: 68px;
}
.promo_asd {
    font-size: 20px;
    line-height: 1.2;
}
.promo_asd span {
    font-size: 60px;
    font-family: Unisans;
    color: #FF704A;
    display: block;
}
.mob1 {
    display: none;
}
.promo_imgtext {
    display: flex;
    gap: 16px;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #D4D4D4;
    /* background: rgba(255, 255, 255, 0.02); */
    /* backdrop-filter: blur(1px); */
    background-color: rgb(244 244 244);
    width: 100%;
    max-width: max-content;
    margin-bottom: 25px;
    z-index: 1;
}
.promo_imgtext_mob {
    display: none;
}
.promo_imgtext p {
    color: #545454;
    font-size: 18px;
    line-height: 1.2;
}
.promo_imgtext_mob br {
    display: block !important;
}
.promo_countdown {
    width: 243px;
}

@media(max-width: 575px) {
    .promo_imgtext p {
        font-size: 16px;
    }
    .promo_imgtext {
        display: none;
    }
    .promo_imgtext_mob {
        display: flex;
        padding: 11px;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
    }
    .mob1 {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -130px;
    }
    .promo_remains {
        display: none;
    }
    .mob1 .promo_remains {
        max-width: 145px;
        display: block;
        margin-bottom: -15px;
        margin-top: 0;
    }
    .promo {
        padding: 20px 0 40px;
        background-image: none;
        height: auto;
    }
    .btns {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        order: 2;
        gap: 14px;
        z-index: 2;
        position: relative;
        margin-bottom: 0;
    }
    .promo_remains_1 {
        text-align: center;
        font-size: 16px;
    }
    .promo_remains_2 {
        font-size: 70px;
        text-align: center;
    }
    .promoblock {
        /* padding: 20px 20px 0; */
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }
    .promo_countdown {
        order: 3;
        text-align: center;
        margin-bottom: 10px;
    }
    .st1, .st2 {
        order: 3;
        text-align: left;
    }
    .st1 {
        font-size: 16px;
    }
    .st1 br {
        display: block!important;
    }
    .st2 {
        font-size: 18px;
    }
    .st1 span {
        font-size: 20px;
    }
    .promo_countdown_timer > div {
        justify-content: flex-start;
        font-size: 48px;
    }
    .promo_imgs {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -22px;
        display: block !important;
    }
    .promo_imgs::before, .promo_imgs::after {
        content: none;
    }
    .promo_imgs img:nth-of-type(2),
    .promo_imgs img:nth-of-type(3) {
        display: none!important;
    }
    .promo_pretitle {
        margin-bottom: 5px;
        font-size: 14px;
    }
    .promo_title {
        font-size: 60px;
        margin-bottom: 15px;
        line-height: 1.2;
    }
    .promo_title::before {
        width: 118px;
        height: 54px;
    }
    .promo_title br {
        display: block!important;
    }
    .promo_subtitle {
        font-size: 16px;
        margin-bottom: 0px;
    }
    .promo_subtitle2 span {
        font-size: 40px;
    }
    .promo_subtitle2 p {
        font-size: 14px;
        line-height: 1.3;
    }
    .promo_remains {
        gap: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: left;
    }
    .promo_remains br {
        display: block!important;
    }
    .promo_remains_2 {  
        text-align: left;
        font-size: 42px;
        margin-bottom: 0;
        order: 2;
    }
    .mob_img {
        display: block;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;
        border-radius: 40px;
        background: #F85572;
        box-shadow: 0px 0px 56.9px 0px rgba(0, 0, 0, 0.07);
        text-align: center;
    }
    .mob_img .promo_remains {
        display: block;
        position: absolute;
        right: 12px;
        top: 12px;
    }
    .mob_img .promo_remains_1 {
        font-size: 15px;
    }
    .mob_img .promo_remains_1 br {
        display: block!important;
    }
    .mob_img .promo_remains_2 {
        font-size: 70px;
    }
    .mob_img img {
        max-height: 275px;
        /* margin: 0 auto; */
        /* margin-left: -20px; */
    }
    .promo_btn {
        width: 100%;
        font-size: 24px;
        z-index: 2;
        justify-content: center;
        height: 80px;
    }
    .promo_btn p {
        /* max-width: 140px; */
        text-align: center;
    }
    .promo_text {
        margin-bottom: 7px;
        font-size: 18px;
    }
    .promo_next {
        margin-bottom: 0;
        font-size: 20px;
    }
    .promo_asd {
        text-align: center;
        font-size: 46px;
    }
}