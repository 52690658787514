.prize {
    padding: 100px 0;
}
.forlines {
    overflow: hidden;
    position: relative;
    height: 72px;
}
.line {
    width: 10000px;
    z-index: 0;
    position: absolute;
    top: 0;
    overflow: hidden;
}
.line_text {
    border-top: 1px solid #DCDEE8;
    border-bottom: 1px solid #DCDEE8;
    background: #DCDEE8;
    padding: 20px 0;
    align-items: center;
    display: flex;
    gap: 20px;
    overflow: hidden;
    animation: linemove 30s linear infinite;
}
.line_text p {
    text-transform: uppercase;
    color: #626DA4;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
}

@keyframes linemove {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-1010px);
    }
}
.line2 {
    width: 10000px;
    z-index: 0;
    position: absolute;
    bottom: 0;
    overflow: hidden;
}
.line2 .line_text {
    animation: linemove2 30s linear infinite;
}
@keyframes linemove2 {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-1135px);
    }
}
.b1 {
    /* padding: 60px 60px 80px; */
    display: flex;
    gap: 40px;
    max-width: 1160px;
    width: 100%;
    margin: 0 auto 100px;
    border-radius: 50px;
    border: 1px solid #F1E9FF;
    background: #F4F4F4;
    overflow: hidden;
}
.b1_info {
    flex: 1;
    padding: 50px 50px 50px 60px;
}
.b1_info_title {
    margin-bottom: 20px;
    font-family: Unisans;
    font-weight: bold;
    font-size: 46px;
    line-height: 1.1;
    text-transform: uppercase;
}
.b1_info_title span {
    color: #5AA675;
}
.b1_info_subtitle {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 1.4;
}
.b1_st2 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4;
}
.b1_info_text {
    display: flex;
    gap: 20px;
    align-items: center;
}
.b1_info_text p {
    /* color: #9069D4; */
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
}
.b1_info_text p b {
    color: #5AA675;
}
.b1_img {
    flex: 0 1 500px;
    max-width: 500px;
    position: relative;
}
/* .b1_img::before {
    content: '';
    position: absolute;
    right: -45px;
    top: 65px;
    width: 140px;
    height: 140px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 140 140" fill="none"><path d="M136.955 102.097L118.359 100.181C105.915 98.8983 95.089 108.66 95.0807 121.167L95.0678 139.862L83.2752 125.358C75.3804 115.653 60.8279 114.9 51.9763 123.739L38.7487 136.948L40.6655 118.352C41.948 105.909 32.1863 95.0829 19.679 95.0748L0.986538 95.0697L15.4907 83.2771C25.1963 75.3823 25.95 60.8301 17.1107 51.9788L3.9018 38.7518L22.498 40.6682C34.9411 41.9504 45.7675 32.1888 45.7758 19.6817L45.7799 0.985873L57.5725 15.4895C65.4673 25.1948 80.0198 25.9482 88.8714 17.1089L102.099 3.9001L100.182 22.4959C98.8997 34.9388 108.661 45.7648 121.169 45.7729L139.864 45.7856L125.36 57.5782C115.654 65.4729 114.9 80.0252 123.74 88.8765L136.949 102.103L136.955 102.097Z" fill="%239069D4"/></svg>');
} */

.t2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: InterT;
    font-size: 40px;
    line-height: 1.2;
}
.t2 span {
    color: #5AA675;
}
.st2 {
    margin-bottom: 10px;
    text-align: center;
    color: #282828CC;
    font-size: 20px;
    line-height: 1.4;
}
.b2 {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto 0px;
}
.b2_item {
    height: 260px;
    width: 260px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    background-color: #D4F6D2;
}
.b2_item img {
    position: absolute;
    bottom: 0;
}
.b2_item::before {
    content: '';
    position: absolute;
    bottom: -58%;
    transform: rotate(-13deg);
    background-color: #5AA675;
    width: 120%;
    height: 100%;
}
.b2_item p {
    color: #111010;
    text-align: center;
    position: absolute;
    top: 10px;
    width: 100%;
    font-size: 18px;
    line-height: 1.25;
    font-weight: bold;
    padding: 20px 15px;
}
.b2_item p span {
    display: block;
    font-weight: normal;
    font-size: 16px;
}
.b2_item p div {
    font-size: 16px;
    color: #535353;
    line-height: 1.25;
    margin-top: 3px;
    font-weight: normal;
}
.b2_item p div b {
    color: #5AA675;
    font-weight: normal;
    display: block;
}
.pt3 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 1.4;
}
.t3 {
    margin-bottom: 20px;
    font-family: Unisans;
    font-size: 46px;
    font-weight: bold;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
}
.t3 span {
    color: #5AA675;
}
.t3 em {
    font-family: Manrope;
    font-style: normal;
}
.st3 {
    color: #282828CC;
    text-align: center;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.4;
}
.b3 {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 62px;
}
.b3_item {
    flex: 0 1 360px;
    max-width: 360px;
    border-radius: 20px;
    background-color: #F4F4F4;
    text-align: center;
}
.b3_item_img {
    margin-top: -50px;
    margin-bottom: 5px;
}
.b3_item_place {
    color: rgba(40, 40, 40, 0.8);
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.78;
    letter-spacing: -0.29px;
}
.b3_item_prize {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.b4 {
    max-width: 1160px;
    margin: 0 auto 45px;
    display: flex;
    gap: 55px;
}
.b4_left {
    flex: 0 1 351px;
    max-width: 351px;
}
.b4_left p {
    font-family: Unisans;
    font-weight: bold;
    font-size: 38px;
    line-height: 1.1;
    position: sticky;
    top: 20px;
}
.b4_right {
    flex: 1;
}
.b4_right_item {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(148, 148, 148, .2);
    display: flex;
    gap: 24px;
    justify-content: space-between;
}
.b4_right_item_num {
    color: #5AA675;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.15;
    text-transform: uppercase;
    flex: 0 1 60px;
    max-width: 60px;
}
.b4_right_item_text {
    font-size: 20px;
    line-height: 1.4;
    color: rgba(40, 40, 40, 0.8);
    flex: 1;
}
.b4_right_item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.b4_right_item_img {
    overflow: hidden;
    border-radius: 18px;
    flex: 0 1 68px;
    max-width: 68px;
    height: max-content;
}
.btn {
    position: relative;
    transition: .2s ease-in-out;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    text-align: center;
    width: max-content;
    padding: 25px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.36deg, #54AC3B -50.46%, #23845D 99.6%), #FFFFFF33;
    border-radius: 60px;
    cursor: pointer;
    margin: 30px auto 0px;
}
.btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #FFFFFF33;
}
.btn:hover {
    transform: translateY(-4px);
}
.br2 {
    display: none !important;
}

@media(max-width: 575px) {
    .br2 {
        display: block !important;
    }
    .prize {
        padding: 80px 0;
    }
    .b1 {
        /* padding: 30px 20px; */
        flex-direction: column;
        border-radius: 40px;
        margin-bottom: 60px;
    }
    .b1_info {
        padding: 20px;
    }
    .b1_info_title {
        margin-bottom: 10px;
        font-size: 24px;
    }
    .b1_info_subtitle {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .b1_st2 {
        font-size: 18px;
    }
    .b1_info_text p {
        font-size: 16px;
    }
    .b1_img {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b1_img::before {
        left: 20px;
        right: auto;
        top: auto;
        bottom: 40px;
        width: 108px;
        height: 108px;
        background-size: cover;
    }
    .t2 {
        font-size: 24px;
    }
    .st2 {
        font-size: 18px;
    }
    .b2 {
        gap: 8px;
        margin-bottom: 30px;
    }
    .b2_item {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 265px;
        max-width: 265px;
    }
    .b2_item p {
        font-size: 13px;
        top: 4px;
        padding: 0;
    }
    .b2_item p span {
        font-size: 12px;
    }
    .b2_item p div {
        font-size: 16px;
        margin-top: 0;
    }
    .pt3 {
        font-size: 18px;
    }
    .t3 {
        font-size: 24px;
    }
    .st3 {
        font-size: 16px;
    }
    .b3 {
        flex-direction: column;
        gap: 80px;
    }
    .b3_item {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b3_item_place {
        font-size: 14px;
    }
    .b3_item_prize {
        font-size: 26px;
    }
    .b4 {
        flex-direction: column;
    }
    .b4_left {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .b4_left p {
        font-size: 20px;
    }
    .b4_right_item {
        gap: 0;
    }
    .b4_right_item_num {
        font-size: 30px;
    }
    .b4_right_item_text {
        font-size: 16px;
    }
    .b4_right_item_img {
        display: none;
    }
    .btn {
        font-size: 18px;
        text-align: center;
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
    }
}
@media(max-width: 379px) {
    .b2_item p {
        font-size: 13px;
        padding: 30px;
    }
    .b2_item p span {
        font-size: 10px;
    }
}