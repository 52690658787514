.promo {
    padding-top: 100px;
}
.header {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.header_link {
    position: relative;
    font-size: 14px;
    line-height: 2.3;
    color: #959BAA;
    cursor: pointer;
}
.header_link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 1px;
    background-color: #959BAA;
}

.main {
    display: flex;
    gap: 20px;
    margin-bottom: 75px;
}
.main_left {
    display: flex;
    padding-left: 40px;
}
.main_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 260px;
    max-width: 260px;
}
.text {
    position: relative;
}
.inf1 {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 10px;
}
.inf1 p {
    line-height: 1.25;
    color: #717DA1;
}
.inf1 p b {
    font-weight: 600;
}
.t1 {
    font-size: 40px;
    letter-spacing: -0.5px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 30px;
    margin-left: -12px;
}
.t1 p {
    background-color: #fff;
    padding: 6px 12px;
    border-radius: 18px;
    max-width: max-content;
}
.t1 p:nth-of-type(2) {
    border: 1px solid #333844;
}
.t2 {
    color: #717DA1;
    font-size: 21px;
    line-height: 1.41;
    letter-spacing: -0.42px;
    margin-bottom: 45px;
}
.t2 b {
    font-weight: 600;
}
.img {
    max-width: 455px;
    margin-left: -80px;
    margin-top: -80px;
}
.img img {
    pointer-events: none;
}

.btn {
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 54px;
    background: linear-gradient(96deg, #FF77A0 44.96%, #EA6C92 147.95%);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.44px;
    padding: 20px 35px;
    cursor: pointer;
    min-width: 340px;
}
.btn:hover {
    transform: translateY(-4px);
}
.countdown {
    margin-top: 35px;
}
.countdown > p {
    text-transform: uppercase;
    color: #3B4A77;
    font-size: 21px;
    line-height: 1.31;
    font-weight: 500;
    letter-spacing: 2.3px;
    margin-bottom: 12px;
}
.countdown_timer div div {
    color: #FF598B;
    font-size: 152px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -11.1px;
    text-transform: uppercase;
    display: flex;
    gap: 4px;
    align-items: baseline;
}
.countdown_timer div div p {
    min-width: 180px;
}
.countdown_timer div div span {
    color: #ABB4BE;
    text-transform: lowercase;
    font-size: 28px;
    letter-spacing: -1.1px;
}
.img .btn,
.text > img {
    display: none!important;
}
.img > img:nth-of-type(2) {
    display: none!important;
}

.more {
    position: relative;
    margin-bottom: 32px;
}
.more::before {
    content: '';
    border-radius: 100%;
    background: linear-gradient(180deg, rgba(227, 237, 255, 0.00) 49.73%, #E3EDFF 145.9%);
    position: absolute;
    left: 50%;
    bottom: 25px;
    width: 1150px;
    height: 183px;
    pointer-events: none;
    margin-left: -575px;
    z-index: -1;
}
.more_title {
    margin-bottom: 18px;
    text-align: center;
    color: #333844;
    text-transform: uppercase;
    font-size: 44px;
    font-weight: 500;
    line-height: 1.2;
}
.more_subtitle {
    margin-bottom: 24px;
    line-height: 1.3;
    text-align: center;
}
.more_btn {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    cursor: pointer;
}

@media(max-width:575px) {
    .header {
        margin-bottom: 20px;
    }
    .promo {
        padding-top: 20px;
    }
    .main {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 40px;
    }
    .main_left {
        flex-direction: column;
        padding-left: 0;
    }
    .main_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .text {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .inf1 {
        margin-bottom: 10px;
    }
    .inf1 img {
        display: none!important;
    }
    .inf1 p {
        font-size: 15px;
    }
    .t1 {
        font-size: 24px;
        margin-bottom: 20px;
        margin-left: 0;
    }
    .t1 p {
        padding: 2px 4px;
        padding: 0;
    }
    .t1 p:last-of-type {
        padding: 5px 10px;
        margin-left: -10px;
    }
    .t2 {
        margin-bottom: 0;
        font-size: 16px;
    }
    .img {
        margin-left: 0;
        margin-top: 0;
        position: relative;
    }
    .img .btn {
        display: flex!important;
        margin-top: 15px;
        font-size: 18px;
        padding: 12px 24px;
        max-width: 100%;
    }
    .main_left .btn {
        display: none;
        min-width: 100%;
    }
    .text > img {
        display: none!important;
        margin-top: -20px;
    }
    .img > img:nth-of-type(1) {
        display: none!important;
    }
    .img > img:nth-of-type(2) {
        display: block!important;
    }
    .countdown {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
    }
    .countdown div {
        display: flex;
        gap: 6px;
        justify-content: space-between;
    }
    .countdown > p {
        font-size: 15px;
    }
    .countdown > p br {
        display: block!important;
    }
    .countdown_timer div div {
        font-size: 40px;
        letter-spacing: 0;
    }
    .countdown_timer div div p {
        min-width: 0;
    }
    .countdown_timer {
        display: flex;
    }
    .countdown_timer div div span {
        font-size: 12px;
    }
    .more_title {
        font-size: 26px;
    }
    .more_subtitle {
        font-size: 18px;
    }
    .more_btn {
        width: 40px;
        height: 40px;
    }
}