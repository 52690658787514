.studentres {
    overflow: hidden;
    position: relative;
    padding: 50px 0 0px;
}
.pretitle {
    text-align: center;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 1.4;
}
.pretitle b {
    font-weight: 600;
    color: #fff;
}
.title {
    text-align: center;
    font-family: InterT;
    /* text-transform: uppercase; */
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 1.1;
}
.title span {
  color: #5AA675;
}
.subtitle {
    margin-bottom: 42px;
    text-align: center;
    color: rgba(40, 40, 40, 0.8);
    font-size: 18px;
    line-height: 1.4;
}
.slider_block {
    max-width: 1660px;
    padding: 0px 15px;
    margin: 0 auto;
    position: relative;
}
.slider_nav {
    display: flex;
    gap: 16px;
    position: absolute;
    top: 50%;
    width: calc(100% + 140px);
    justify-content: space-between;
    margin-top: -80px;
    left: -70px;
}
.slider_btn_prev {
    background-color: #5AA675;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next {
    background-color: #5AA675;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 1;
}
.slider_btn_next img,
.slider_btn_prev img {
    /* filter: brightness(0); */
}
.slider {
    margin-bottom: 45px;
}
.slide {
    border: 1px solid #E8E8E8;
    border-radius: 38px;
    background-color: #fff;
    overflow: hidden;
}
.slide_info {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
}
.slide_info div {
    flex: 0 1 50%;
    max-width: 50%;
    font-size: 15px;
    color: #535363;
}
.slide_info div:nth-of-type(1),
.slide_info div:nth-of-type(2) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(236, 236, 236, 0.5);
}
.slide_info div img {
    filter: brightness(0.1);
    margin-top: -2px;
    margin-right: 2px;
}
.slider .slider_nav {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
}
.slide_img {
    position: relative;
}
.slide_img > p {
    position: absolute;
    left: 18px;
    bottom: 18px;
    border-radius: 55.051px;
    background: #5AA675;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    padding: 4px 14px;
}
.slide_img > div {
    position: absolute;
    right: 18px;
    bottom: 18px;
}
.btn {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 415px;
    height: 100px;
    padding: 10px;
    border-radius: 30px;
    background: linear-gradient(92deg, rgba(255, 255, 255, 0.40) -50.46%, rgba(255, 255, 255, 0.00) 99.6%), #FE5858;
    font-size: 24px;
    font-family: Manrope;
    font-weight: 500;
    color: #fff;
    line-height: 1.4;
    cursor: pointer;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}
.btn:hover {
    transform: translateY(-4px);
}
.btns {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}
.promo_btn {
  position: relative;
  max-width: 415px;
  width: 100%;
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Manrope';
  padding: 20px 32px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;   
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  border-radius: 60px;
  background: linear-gradient(90.67deg, #54AC3B -28.4%, #23845D 139.07%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
      transition: .2s ease-in-out;
}
.promo_btn::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #FFFFFF33;
}
.promo_btn:hover {
  transform: translateY(-4px);
}
.btns > p {
  color: #5AA675;
  font-family: Lighthaus;
  font-size: 55px;
  line-height: 1;
  letter-spacing: -0.2px;
}
.students_countdown {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    min-width: 280px;
    margin-top: 20px;
    flex-direction: column;
}
.students_countdown > p {
    font-size: 22px;
    color: rgba(40, 40, 40, 0.8);
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
}
.students_countdown div {
    display: flex;
    justify-content: flex-start;
    color: #5AA675;
    font-weight: 600;
    font-size: 56px;
}
  
@media (max-width: 575px) {
  
    .studentres {
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .pretitle {
      font-size: 18px;
    }
    .title {
      font-size: 24px;
      text-align: center;
    }
    .title br{
      display: block !important;
    }
    .slider {
      margin: 0 0 30px;
    }
    .btn {
      width: 100%;
      font-size: 18px;
      height: 80px;
      padding: 10px;
    }
    .slide_info div {
      font-size: 13px;
    }
    .slider .slider_nav {
      display: flex;
    }
    .slider_nav {
      position: absolute;
      top: 50%;
      padding: 0 10px;
      left: 0;
      z-index: 2;
      width: 100%;
      justify-content: space-between;
    }
    .slider_btn_next {
      width: 42px;
      height: 42px;
    }
    .slider_btn_prev {
      width: 42px;
      height: 42px;
    }
    .slide_info {
      padding: 17px 8px;
    }
    .title span {
      padding: 0;
      border: none;
    }
    .btns {
      flex-direction: column;
      gap: 10px;
    }
    .students_countdown {
      flex-direction: column;
      gap: 5px;
    }
    .students_countdown > p {
        max-width: 380px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
    }
    .students_countdown div {
        justify-content: center;
        font-size: 40px;
    }
    .promo_btn {
      width: 100%;
      font-size: 16px;
      padding: 25px 20px;
      text-align: center;
      height: 60px;
    }
  }
  