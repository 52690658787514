.callback {
    /* background-color: #0b0b0b; */
    padding-bottom: 40px;
}
.callback_block {
    border-radius: 32px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.06) inset;
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    padding: 45px 56px;
}
.callback_left {
    flex: 0 1 490px;
    max-width: 490px;
}
.callback_right {
    flex: 1;
}
.callback_title {
    font-family: Nface;
    color: #141414;
    font-size: 40px;
    line-height: 1.25;
}
.callback_links {
    display: flex;
    justify-content: flex-end;
    gap: 45px;
}
.oferta {
    display: flex;
    flex-direction: column;
}
.oferta a {
    font-size: 18px;
    line-height: 1.55;
    font-weight: 600;
    max-width: max-content;
    transition: .2s ease-in-out;
    margin: 40px auto 0;
}
.oferta a:hover {
    color: #FF84A9;
}

@media(max-width: 575px) {
    .oferta {
        margin-top: 0px;
        margin-bottom: 0px;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .oferta a {
        font-size: 16px;
        margin: 40px 0px 0px;
    }
    .callback_block {
        flex-wrap: wrap;
        padding: 30px 20px 0;
    }
    .callback_left, .callback_right {
        flex: 0 1 100%;
        max-width: 100%;
    }
    .callback_title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 16px;
    }
    .callback_links {
        flex-wrap: wrap;
        gap: 0;
        justify-content: center;
    }
    .callback_links img {
        max-width: 50px;
    }
    .callback_links a {
        flex: 0 1 20%;
        max-width: 20%;
        text-align: center;
        margin-bottom: 30px;
    }
}