.prizes {
    padding: 20px 0 70px;
    /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/womday/prizes_bg.jpg'); */
    background-size: cover;
    background-color: #FEF0F0;
    background-position: 50% 0%;
}
.prizes_title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-weight: 800;
    color: #111010;
    font-size: 44px;
    line-height: 1.15;
}
.prizes_title span {
    color: #E8507B;
}
.prizes_subtitle {
    text-align: center;
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 45px;
}
.prizes_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 70px;
    max-width: 1618px;
}
.prizes_item {
    flex: 0 1 270px;
    max-width: 270px;
    padding: 0 10px;
    margin-bottom: 20px;
    height: 270px;
}
.prizes_info {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.06) inset;
    backdrop-filter: blur(50px);
    height: 100%;
    position: relative;
    padding: 20px 24px;
    overflow: hidden;
    transition: .3s ease-in-out;
}
.prizes_info::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 106px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #E0E7DA;
}
.prizes_info:hover {
    transform: translateY(-4px);
}
.prizes_info_img {
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
    width: 100%;
    text-align: center;
}
.prizes_info_name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    color: #111010;
    text-align: center;
}
.prizes_info_availiable {
    font-size: 16px;
    line-height: 1.1;
    font-weight: 500;
    text-align: center;
}
.prizes_info_availiable span {
    color: #E8507B;
    font-weight: bold;
}
.prizes_info_number {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 24px;
    bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFBD6A;
    border-radius: 100%;
    font-weight: bold;
    color: #090808;
    font-size: 20px;

    display: none!important;
}

.title2 {
    text-align: center;
    padding: 0 15px;
    margin-bottom: 45px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.15;
    font-size: 44px;
    color: #111010;
}
.title2 span {
    color: #E8507B;
}
.block2 {
    display: flex;
    justify-content: center;
    padding: 0 15px;
    gap: 140px;
}
.item2 {
    position: relative;
    text-align: center;
}
.item2_img {
    display: none;
}
.item2::before {
    /* content: ''; */
    position: absolute;
    left: 50%;
    margin-left: 65px;
    top: 46px;
    width: 200px;
    height: 2px;
    background-color: #FCB6C8;
    opacity: .5;
}
.item2:last-of-type:before {
    content: none;
}
.item2_number {
    /* margin-top: 16px; */
    margin-bottom: 4px;
    color: #F9658B;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.3;
}
.item2_text {
    color: #111010;
    line-height: 1.35;
    font-size: 20px;
}

.ez {
    padding: 25px 0 70px;
    /* background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/womday/ez_bg.jpg'); */
    background-color: #FEF0F0;
    background-size: cover;
    background-position: 50% 50%;
}
.ez_title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 12px;
    font-weight: 800;
    color: #111010;
    font-size: 44px;
    line-height: 1.15;
}
.ez_subtitle {
    margin-bottom: 40px;
    text-align: center;
    font-size: 22px;
    line-height: 1.3;
}
.ez_block {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.ez_item {
    flex: 0 0 379px;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000A;
    padding: 24px;
    border-radius: 20px;
    transition: .3s ease-in-out;
    display: flex;
    gap: 18px;
    align-items: center;
}
.ez_item:hover {
    background-color: #F95B83;
}
.ez_item_text {
    flex: 1;
}
.ez_item_text p {
    transition: .3s ease-in-out;
    line-height: 1.3;
}
.ez_item_text p:first-of-type {
    margin-bottom: 12px;
    color: #F95B83;
    font-size: 24px;
}
.ez_item_text p:last-of-type {
    font-size: 20px;
    font-weight: 400;
}
.ez_item_img {
    flex: 0 0 91px;
}
.ez_item:hover .ez_item_text p {
    color: #fff!important;
}
.ez_item_img svg, .ez_item_img svg path {
    transition: .3s ease-in-out;
}
.ez_item:hover svg, .ez_item:hover svg path {
    fill: #fff!important;
}
.prizes_info p {
    text-align: center;
}
@media(max-width: 1449px) {
    .prizes_block {
        justify-content: center;
        margin-bottom: 50px;
    }
}
@media(max-width: 575px) {
    .prizes {
        padding: 40px 0;
    }
    .prizes_title {
        font-size: 28px;
        margin-bottom: 8px;
        padding: 0 10px;
    }
    .prizes_title br {
        display: none;
    }
    .prizes_subtitle {
        margin-bottom: 30px;
        font-size: 18px;
        padding: 0 15px;
    }
    .prizes_item {
        flex: 0 1 50%;
        max-width: 50%;
        padding: 0 6px;
        height: 166px;
        margin-bottom: 12px;
    }
    .prizes_info {
        padding: 10px;
    }
    .prizes_info_img img {
        max-width: 110px;
    }
    .prizes_info_name {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .prizes_info_availiable {
        font-size: 10px;
    }
    .prizes_info::before {
        height: 65px;
    }
    .prizes_info_number {
        width: 30px;
        height: 30px;
        font-size: 12px;
        right: 17px;
        bottom: 17px;
    }
    .prizes_info p {
        font-size: 10px;
    }
    .prizes_item:nth-of-type(1) .prizes_info_img img {
        max-width: 80px;
    }
    .prizes_item:nth-of-type(4) .prizes_info_img img {
        max-width: 150px;
    }
    .prizes_item:nth-of-type(5) .prizes_info_img img {
        max-width: 120px;
    }
    .prizes_item:nth-of-type(6) .prizes_info_img img {
        max-width: 120px;
    }
    .prizes_item:nth-of-type(7) .prizes_info_img img {
        max-width: 100px!important;
    }
    .prizes_item:nth-of-type(8) .prizes_info_img img {
        max-width: 108px;
    }
    .prizes_item:nth-of-type(9) .prizes_info_img img {
        max-width: 100px;
    }
    .prizes_item:nth-of-type(10) .prizes_info_img img {
        max-width: 100px;
    }
    .prizes_item:nth-of-type(11) .prizes_info_img img {
        max-width: 130px;
    }
    .prizes_item:nth-of-type(12) .prizes_info_img img {
        max-width: 140px;
    }
    .prizes_item:nth-of-type(15) .prizes_info_img img {
        max-width: 80px;
    }
    .prizes_item:nth-of-type(16) .prizes_info_img img {
        max-width: 90px;
    }
    .title2, .ez_title {
        font-size: 28px;
    }
    .ez {
        background-image: none;
    }
    .block2 {
        flex-direction: column;
        gap: 30px;
    }
    .item2::before {
        content: none;
    }
    .item2_number {
        /* margin-top: 10px; */
    }
    .item2_text {
        font-size: 16px;
    }
    .ez_subtitle {
        font-size: 18px;
    }
    .ez_item {
        flex: 0 0 100%;
        padding: 20px;
    }
    .ez_item_text p:last-of-type {
        font-size: 16px;
    }
}