.start {
    background-size: cover;
    background-position: 50% 50%;
    padding: 0px 0 110px;
}
.line {
    width: 10000px;
    z-index: 0;
    top: 0;
    overflow: hidden;
}
.line_text {
    border-top: 1px solid #DCDEE8;
    border-bottom: 1px solid #DCDEE8;
    background: #DCDEE8;
    padding: 20px 0;
    align-items: center;
    display: flex;
    gap: 20px;
    overflow: hidden;
    animation: linemove 30s linear infinite;
}
.line_text p {
    text-transform: uppercase;
    color: #626DA4;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
}
@keyframes linemove {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-890px);
    }
}
.block {
    border-radius: 80px;
    background: #fff;
    box-shadow: 0px 0px 33.5px 0px #0000000A;
    backdrop-filter: blur(50px);
    padding: 60px 90px;
    max-width: 960px;
    margin: 0 auto;
}
.pretitle {
    text-align: center;
    margin-bottom: 24px;
    font-size: 20px;
    color: #797F84;
    line-height: 1.4;
    text-transform: uppercase;  

}
.pretitle b {
    font-weight: 600;
    color: rgba(40, 40, 40, 1);
}
.title {
    text-align: center;
    margin-bottom: 10px;
    font-family: InterT;
    font-size: 40px;
    line-height: 1.1;
}
.title span {
    color: #5AA675;
}
.subtitle {
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.3;
}
.remains_n_place {
    font-family: InterT;
    font-size: 30px;
    line-height: 1;
    letter-spacing: -0.3px;
    margin-bottom: 50px;
    text-align: center;
}
.remains_n_place span {
    color: #5AA675;
}
.prices {
    text-align: center;
    font-family: InterT;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 24px;
}
.prices em {
    font-style: normal;
    text-decoration: line-through;
    color: #28282847;
}
.prices i {
    font-style: normal;
    font-family: Manrope;
}
.prices span {
    display: block;
    color: rgba(40, 40, 40, 0.8);
    font-size: 18px;
    line-height: 1.4;
    font-weight: normal;
    font-family: Manrope;
}
.remains {
    max-width: 640px;
    margin: 0 auto 50px;
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 20px 30px;
    border-radius: 32px;
    background: #DDD4EC;
}
.timer2 > div {
    display: flex;
    font-family: InterT;
    font-weight: bold;
    font-size: 74px;
    line-height: 1.1;
}
.timer2 > div span {
    margin-left: -4px;
    margin-right: 4px;
}
.remains_text {
    flex: 0 1 230px;
    max-width: 230px;
    color: rgba(40, 40, 40, 0.8);
    font-size: 22px;
    line-height: 1.4;
}
.remains_text b {
    font-weight: 600;
    color: rgba(40, 40, 40, 1);
}
.remains_number {
    flex: 1;
    font-size: 90px;
    font-family: InterT;
    font-weight: bold;
    line-height: 1.1;
}
.dts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    flex: 1;
    margin: 0 -25px 24px;
}
.dts_item {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 5000px;
    background: #EBF0EE;
    padding: 8px 14px;
}
.dts_item_text {
    font-family: Manrope;
    font-size: 19px;
    line-height: 1.3;
    font-weight: 500;
    text-transform: uppercase;
    color: #797F84;
}
.dts_item img {
    max-width: 32px;
}
.info_all {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 24px;
}
.info_all_name {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
}
.info_all_prices {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0 12px;
    color: #5AA675;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    text-align: right;
    flex: 0 1 232px;
    max-width: 232px;
}
.info_all_prices p {
    /* font-size: 24px; */
}
.info_all_prices em {
    text-decoration: line-through;
    color: #282828;
    font-size: 18px;
    font-style: normal;
}
.info_all_prices span {
    display: block;
    color: #282828;
    font-size: 18px;
    flex: 0 1 100%;
}
.info {
    margin-bottom: 50px;
}
.info_item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(71, 71, 71, .2);
}
.info_item:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.info_item p {
    /* color: rgba(255, 255, 255, 0.8); */
    font-size: 18px;
    line-height: 1.3;
}
.info_item p b {
    font-weight: 400;
    /* color: #fff; */
}
.info_item span {
    text-decoration: line-through;
    /* color: rgba(255, 255, 255, 0.8); */
    font-size: 18px;
    line-height: 1.3;
}
.info_more {
    font-size: 18px;
    margin-bottom: 38px;
    line-height: 1.3;
}
.pay {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.pay_item {
    text-align: center;
}
.pay_item img {
    margin-bottom: 20px;
}
.pay_item p {
    font-size: 25px;
    margin-bottom: 6px;
    font-weight: 600;
    line-height: 1.3;
}
.pay_item span {
    color: rgba(40, 40, 40, 0.8);
    font-size: 18px;
    line-height: 1.4;
}
.oh {
    overflow: hidden;
}
.timer {
    margin-top: 25px;
    margin-bottom: 25px;
}
.timer b{
    color: #5AA675;
}
.timer > p {
    text-align: center;
}
.timer > div {
    color: #5AA675;
    font-family: InterT;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 46px;
    line-height: 1.1;
    /* color: #8356CF; */
}
.timer > div span {
    margin-right: 5px;
    margin-left: -5px;
}
.btn {
    position: relative;
    transition: .2s ease-in-out;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    text-align: center;
    width: max-content;
    padding: 25px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.36deg, #54AC3B -50.46%, #23845D 99.6%),#FFFFFF33;
    border-radius: 60px;
    cursor: pointer;
    margin: 0px auto;
}
.btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: #FFFFFF33;
}
.btn:hover {
    transform: translateY(-4px);
    box-shadow: 0px 4px 25.5px 0px#54AC3B;
}
.br1 {
    display: none !important;
}

@media(max-width:575px) {
    .count_text {
        font-size: 24px;
        display: inline-block;
    }
    .br1 {
        display: block !important;
    }
    .remains_n_place {
        font-size: 20px;
        text-align: center;
    }
    .btn {
        height: 70px;
        font-size: 18px;
        line-height: 1;
        text-align: center;
    }
    .start {
        padding: 40px 0;
    }
    .block {
        padding: 40px 15px;
        border-radius: 40px;
    }
    .pretitle {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .title {
        margin-bottom: 26px;
        font-size: 26px;
    }
    .prices {
        font-size: 26px;
    }
    .remains {
        padding: 15px;
        flex-direction: column;
        /* align-items: flex-start; */
        text-align: center;
        gap: 0;
    }
    .remains_text {
        flex: 0 1 100%;
        max-width: 100%;
        font-size: 16px;
    }
    .remains_number {
        font-size: 60px;
    }
    .dts {
        gap: 8px;
        padding: 0;
        flex-direction: column;
        align-items: center;
        margin: 0 0 24px;
    }
    .dts_item_text {
        font-size: 14px;
        text-align: center;
    }
    .dts_item_text span {
        display: block;
    }
    .info_all_name {
        font-size: 18px;
        line-height: 1.2;
    }
    .info_all_prices {
        font-size: 18px;
    }
    .info_all_prices em,
    .info_all_prices span {
        font-size: 16px;
    }
    .info_item p,
    .info_item span {
        font-size: 16px;
    }
    .info_more {
        font-size: 16px;
    }
    .pay_item p {
        font-size: 18px;
    }
    .pay_item span {
        font-size: 16px;
    }
    .timer2 > div {
        font-size: 50px;
    }
}