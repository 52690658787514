.prizes {
    padding: 40px 0 90px;
}
.prizes_pretitle {
    color: #E7533D;
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    line-height: 1.3;
}
.prizes_title {
    text-align: center;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.prizes_title span {
    color: #8FA8E8;
}
.prizes_subtitle {
    text-align: center;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 1.35;
    color: #6A6A6A;
}
.prizes_block {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}
.prizes_item {
    position: relative;
    flex: 0 1 380px;
    max-width: 380px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 32px;
    overflow: hidden;
    height: 235px;
    transition: .3s ease-in-out;
}
.prizes_item::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/gymfest/prize_2.jpg');
    width: 190px;
    height: 199px;
    transition: .3s ease-in-out;
    pointer-events: none;
}
.prizes_item:nth-of-type(2):before {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2025/gymmarch/prize_2.jpg');
}
.prizes_item:nth-of-type(3):before {
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/gymfest/prize_3.jpg');
    width: 170px;
}
.prizes_item:hover {
    transform: translateY(-4px);
}
.prizes_item_number {
    margin-bottom: 8px;
    font-size: 18px;
    color: #707070;
    position: relative;
}
.prizes_item_text {
    text-transform: uppercase;
    color: #131313;
    font-family: Nface;
    font-size: 28px;
    line-height: 1.2;
    position: relative;
}
.prizes_moretitle {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.35;
}
.prizes_pretitle2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.35;
    color: #E7533D;
}
.prizes_title2 {
    text-align: center;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 45px;
}
.prizes_title2 span {
    color: #8FA8E8;
}
.prizes_block2 {
    display: flex;
    /* align-items: center; */
    gap: 40px;
}
.prizes_left {
    flex: 0 1 580px;
    max-width: 580px;
}
.prizes_left img {
    border-radius: 32px;
}
.prizes_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 28px;
}
.prizes_item2 {
    position: relative;
    padding-left: 70px;
}
.prizes_item2::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 30px;
    height: 30px;
    background-color: #FF84A9;
    border-radius: 8px;
}
.prizes_item2::after {
    content: '';
    position: absolute;
    left: 14px;
    top: 7px;
    width: 1px;
    height: 142%;
    background-color: #FF84A9;
}
.prizes_item2:nth-of-type(5)::after {
    content: none;
}
.prizes_item2_number {
    font-family: Nface;
    color: #FF84A9;
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 4px;
}
.prizes_item2_text {
    color: #6A6A6A;
    line-height: 1.35;
    letter-spacing: -0.16px;
}
.prizes_item2_text b {
    color: #282828;
    font-weight: normal;
}
.btn {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    transition: .2s ease-in-out;
    max-width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100px;
    border-radius: 60px;
    background: #FF84A9;
    color: #fff;
    letter-spacing: 1px;
    padding: 20px 79px;
    cursor: pointer;
}
.btn:hover {
    transform: translateY(-4px);
}
.btn_block{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
}
.btn_block span {
    color: #6A6A6A;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    text-align: center;
    display: block;
}
.remains_n_place {
    font-family: Inter;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    gap: 10px;
}
.remains_n_place div {
    display: inline-block;
}
.remains_n_place span {
    margin-bottom: 0px;
    color: #FF84A9;
    font-family: Nface;
    line-height: 1.1;
    letter-spacing: -0.478px;
    font-size: 40px;
}
.remains_n_text {
    color: #FF724D;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
}
.remains_block {
    margin-bottom: 40px;
}
@media(max-width: 575px) {
    .prizes {
        padding: 40px 0;
    }
    .prizes_pretitle {
        font-size: 18px;
    }
    .prizes_title {
        font-size: 22px;
    }
    .prizes_subtitle {
        font-size: 16px;
    }
    .prizes_block {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .prizes_item {
        flex: 0 1 190px;
        max-width: 100%;
        height: 100%;
        padding: 20px;
    }
    .prizes_item::before {
        width: 160px!important;
    }
    .prizes_item_number {
        font-size: 16px;
    }
    .prizes_item_text {
        font-size: 18px;
    }
    .prizes_item_text br {
        display: block!important;
    }
    .prizes_title2 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .prizes_left {
        display: none;
    }
    .prizes_item2 {
        padding-left: 50px;
    }
    .prizes_item2_number {
        font-size: 24px;
    }
    .prizes_item2_text {
        font-size: 16px;
    }
    .promo_btn {
        width: 100%;
        height: 74px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        padding: 0 10px;
    }
}



.promo_more {
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
}
.promo_more__item {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    background-color: #fff;
    border-radius: 32px;
    padding: 20px 30px 30px;
    height: 235px;
    width: 580px;
    position: relative;
    transition: .3s ease-in-out;
}
.promo_more__item a {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.promo_more__item:hover {
    background-color: #FF84A9;
    color: #fff;
}
.promo_more__item p {
    font-family: Nface;
    font-size: 32px;
    line-height: 1.2;
    color: #131313;
    display: flex;
    align-items: flex-end;
    transition: .3s ease-in-out;
}
.promo_more__item_imgs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}
.promo_more__item_imgs img:nth-of-type(1) {
    transition: .3s ease-in-out;
    opacity: 1;
}
.promo_more__item_imgs img:nth-of-type(2) {
    width: 43px;
}
.promo_more__item_imgs img:nth-of-type(3) {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: .3s ease-in-out;
}
.promo_more__item:hover .promo_more__item_imgs img:nth-of-type(3) {
    opacity: 1;
}
.promo_more__item:hover .promo_more__item_imgs img:nth-of-type(1) {
    opacity: 0;
}
.promo_more__item:hover p {
    color: #fff;
}
.promo_more__item2 {
    width: 580px;
    border: 1px solid #2B2B2B;
    background-color: #191919;
    border-radius: 32px;
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
}
.promo_more__item2::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    background-image: url('https://gymteam.kinescopecdn.net/img/l/2024/gymfest/promo_4.png');
    width: 173px;
    height: 236px;
    transition: .3s ease-in-out;
    pointer-events: none;
}
.promo_more__item2 p:nth-of-type(1) {
    margin-bottom: 7px;
    text-transform: uppercase;
    font-family: Nface;
    font-size: 32px;
    line-height: 1.2;
    z-index: 1;
    position: relative;
}
.promo_more__item2 p:nth-of-type(2) {
    font-size: 24px;
    color: #A1A1A1;
    line-height: 1.2;
    z-index: 1;
    position: relative;
    transition: .3s ease-in-out;
}

.countdown p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #2B3137;
    text-align: center;
}
.countdown_timer > div {
    display: flex;
    gap: 3px;
    color: #FF84A9;
    font-size: 60px;
    font-family: Nface;
    line-height: 1.35;
    font-weight: normal;
    justify-content: center;
}
.countdown_timer > div div {
    display: flex;
    gap: 3px;
}
@media(max-width: 575px) {
    .countdown p {
        font-size: 16px;
    }
    .programs {
        padding: 25px 0;
    }
    .remains_n_place span {
        margin-bottom: 0px;
    }
    .countdown_timer > div {
        font-size: 48px;
    }
    .promo_more {
        flex-direction: column;
    }
    .promo_more__item {
        flex: 0 1 100%;
        max-width: 100%;
        width: 100%;
        padding: 20px;
        justify-content: space-between;
        min-height: 130px;
    }
    .promo_more__item p {
        font-size: 18px;
        max-width: 228px;
    }
    .promo_more__item_imgs img {
        max-width: 38px;
    }
    .promo_more__item2 {
        flex: 0 1 100%;
        max-width: 100%;
        width: 100%;
        padding: 20px;
        min-height: 130px;
    }
    .promo_more__item2 p:nth-of-type(1) {
        font-size: 18px;
    }
    .promo_more__item2 p:nth-of-type(2) {
        font-size: 16px;
    }
    .promo_more__item2::before {
        width: 80px;
        height: 100%;
        background-size: cover;
    }
    .prizes_moretitle {
        text-align: center;
        font-size: 18px;
        margin-bottom: 0;
    }
    .btn_block{
        flex-direction: column;
    }
    .remains_n_place {
        font-size: 20px;
        text-align: center;
        gap: 10px;
    }
    .btn {
        font-size: 18px;
        width: 100%;
        height: 80px;
        text-align: center;
        padding: 20px;
        max-width: 100%;
        justify-content: center;
    }
}