.studentres {
    padding: 90px 0 100px;
    overflow: hidden;
    background-color: #FFF9F5;
    position: relative;
}
.studentres::after {
    /* content: ''; */
    position: absolute;
    width: 1218px;
    height: 1218px;
    right: -609px;
    bottom: -412px;
    background: radial-gradient(50% 50% at 50% 50%, #EB0A75 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.6;
    filter: blur(250px);
    pointer-events: none;
}
.studentres_title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 40px;
    line-height: 1.15;
    font-weight: 600;
    position: relative;
    z-index: 1;
}
.studentres_subtitle {
    font-size: 22px;
    color: #585656;
    line-height: 1.4;
    margin-bottom: 40px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.studentres_subtitle span {
    color: rgba(255, 236, 169, 1);
    font-weight: 600;
}
.gallery_item {
    flex: 0 1 20%;
    max-width: 20%;
    padding: 0 10px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.gallery-item img {
    cursor: pointer;
}
.slider_nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    z-index: 1;
}
.slider_btn {
    font-size: 50px;
    line-height: normal;
}
.slider_btn_prev {
    border: 20px solid transparent; border-right: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-left: -15px;
}
.slider_btn_next {
    border: 20px solid transparent; border-left: 20px solid #fff;
    width: 20px;
    height: 20px;
    margin-right: -15px;
}
.lg-sub-html {
    display: none;
}
.btn_more {
    border-radius: 20px;
    background: linear-gradient(95deg, rgba(255, 255, 255, 0.15) 5.8%, rgba(255, 255, 255, 0.00) 92.21%), #F97193;
    box-shadow: 0px 2.617px 5.233px 0px #F38BA5 inset, 0px -2.617px 2.617px 0px #E6476E inset, 0px 7.135px 39.241px 0px rgba(249, 113, 147, 0.06);
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px 44px;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2.2px;
}

@media(max-width: 767px) {
    .studentres {
        padding: 45px 0;
    }
    .studentres_title {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .studentres_subtitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
        text-align: center;
    }
    .studentres_subtitle br {
        display: none;
    }
    .studentres_subtitle span span {
        display: inline-block;
    }
}
@media(max-width: 374px) {
    .studentres_title {
        font-size: 25px;
        line-height: 31px;
    }
    .studentres_subtitle {
        font-size: 16px;
    }
}